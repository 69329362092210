<template>
  <div>
    <transition name="slide-down">
      <improve-text
        v-if="canImproveText && hasTyped"
        :text="selectedText || message"
        @improvedText="onReplaceText"
        @onTextImproved="onTextImproved"
        @onClose="onCloseImproveText"
      />
    </transition>
    <div
      :style="isAnEmail && 'max-height: none'"
      class="reply-box"
      :class="{
        'reply-box-active': showDropZone,
        'is-focused': isFocused,
      }"
      @dragenter.prevent="showDropZone = true"
      @dragleave.prevent="showDropZone = false"
      @drop.prevent="onDropped"
      @dragover.prevent
    >
      <woot-modal
        :show.sync="showDraftModal"
        :on-close="() => (showDraftModal = false)"
        :show-close="true"
        :small-modal="true"
      >
        <div class="modal-content-wrap">
          <woot-modal-header
            :header-title="'Discard draft'"
            :header-content="'Your draft will be discarded. Are you sure?'"
          />
          <div class="button-container flex-row flex-justify--end">
            <woot-secondary-button
              size="medium"
              :custom-style="{ width: '132px' }"
              name="No"
              @click="() => (showDraftModal = false)"
            />
            <woot-primary-button
              size="medium"
              :custom-style="{ width: '132px' }"
              :custom-class="'primary-button'"
              name="Yes"
              @click="deleteDraft"
            />
          </div>
        </div>
      </woot-modal>
      <template v-if="showDropZone && files.length === 0">
        <drop-zone
          :height="200"
          :drop-directory="true"
          :multi-file-support="isAnEmail"
          @fileData="uploadedFileData"
        />
      </template>
      <template v-else>
        <div
          :style="isAnEmail && 'max-height: none'"
          class="reply-box__top"
          :class="{ 'is-private': isPrivate }"
        >
          <div
            v-if="isAPrivateEmail"
            class="flex-row flex-align--center private-note-head"
          >
            <icons name="lock" color="primary" size="medium" />
            <span class="subtitle-s2">Private Note</span>
          </div>
          <canned-response
            v-if="showCannedResponsesList"
            v-on-clickaway="hideCannedResponse"
            data-dropdown-menu
            :on-keyenter="replaceText"
            :on-click="replaceText"
          />
          <templates
            v-if="showTemplateList"
            v-on-clickaway="hideTemplate"
            data-dropdown-menu
            @click="openTemplateModal"
          />
          <reply-email-head
            v-if="showReplyHead"
            :cc-emails.sync="ccEmails"
            :bcc-emails.sync="bccEmails"
            :receiver-emails.sync="toEmails"
            @validEmails="areEmailsValid = $event"
          />
          <emoji-input
            v-if="showEmojiPicker"
            v-on-clickaway="hideEmojiPicker"
            :on-click="emojiOnClick"
          />
          <reply-message
            v-if="replyState"
            :get-reply-data="modifyReplyDataForReplyBox(getReplyData)"
            :theme="replyMessageTheme"
            show-close
            @onClose="() => this.$emit('toggleReply', false)"
          />
          <attachment-preview
            v-if="files.length && attachmentPreview && !isAnEmail"
            :files="files"
            @clear="clearAttachments"
          />
          <div
            class="reply-box-input"
            :class="{ 'flex-direction--column-reverse': isAnEmail }"
          >
            <div
              v-if="files.length && attachmentPreview && isAnEmail"
              class="flex-row flex-justify--start uploaded-files-container"
            >
              <div v-for="file in files" :key="file.id">
                <email-file
                  :id="`${file.id}`"
                  :attachment-type="file.type"
                  :attachment-name="file.name"
                  :attachment-size="file.size"
                  :attachment-url="file.url"
                  can-delete
                  @clear="clearAttachments"
                />
              </div>
            </div>
            <transition name="fade">
              <div
                v-if="isEmailSignatureActive"
                class="email-signature flex-row flex-align--start flex-justify--start"
              >
                <span
                  class="email-signature--container"
                  v-html="emailSignature"
                />
              </div>
            </transition>
            <template-modal
              v-if="showTemplateModal"
              :selected-template="selectedTemplate"
              :parameters="templateParameters"
              :template-media-url.sync="templateMediaUrl"
              :template-button-url.sync="templateButtonUrl"
              @setParams="setTemplateParameter"
              @close="closeTemplateModal"
            />
            <resizable-text-area
              v-if="isAnEmail && isPrivate"
              ref="messageInput"
              v-model="message"
              class="input"
              :placeholder="messagePlaceHolder"
              :min-height="20"
              :is-an-email="true"
              @focus="onFocus"
              @blur="onBlur"
              @fileData="uploadedFileData"
            />
            <text-editor
              v-if="isAnEmail && !isPrivate"
              v-model="message"
              auto-focus
              no-border
              is-menu-bar-required
              is-format-mode
              for-email
              :min-height="20"
              :placeholder="messagePlaceHolder"
              @focus="onFocus"
              @blur="onBlur"
            />
            <resizable-text-area
              v-if="shouldDisplayTextArea"
              ref="messageInput"
              v-model="message"
              class="input"
              :placeholder="messagePlaceHolder"
              :min-height="4"
              :retain-selection-on-focus="canImproveText"
              @focus="onFocus"
              @blur="onBlur"
              @fileData="uploadedFileData"
              @onSelectText="text => (selectedText = text)"
            />
            <div
              v-if="!showTemplateModal && shouldDisplayTextArea && !isAnEmail"
              class="reply-box-icons flex-row flex-align gap--small"
            >
              <span
                v-if="!isTemplateTabActive"
                ref="improveTextButton"
                class="reply-box-item"
                @click="improveText"
              >
                <icons
                  name="wand"
                  size="medium"
                  :color="message.trim().length ? 'green' : 'grey'"
                  :show-title="false"
                />
              </span>
              <span
                v-tooltip.top="attachmentUploadTooltip"
                class="reply-box-item"
              >
                <file-upload
                  v-if="inbox.channel_type !== 'Channel::Freshchat'"
                  ref="uploadNonEmail"
                  v-model="files"
                  :size="4096 * 4096"
                  accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
                  :class="{ disabled: disableAttachmentUpload }"
                  @input-file="onFileUpload"
                >
                  <icons
                    v-if="!isUploading"
                    name="clip"
                    size="medium"
                    color="secondaryblue"
                    view="0 -4 28 28"
                  />
                </file-upload>
              </span>
              <woot-spinner v-if="isUploading" />
              <span class="reply-box-item">
                <img
                  src="~/assets/images/emoji.svg"
                  class="emoji-picker"
                  @click="toggleEmojiPicker"
                />
              </span>
            </div>
          </div>
        </div>
      </template>

      <div
        class="reply-box__bottom flex-row flex-align"
        :class="isAnEmail ? 'flex-justify--between' : 'flex-justify--evenly'"
      >
        <ul v-if="!isAnEmail" class="tabs">
          <li
            class="tabs-title"
            :class="{
              'is-active': !isPrivate && !isTemplateTabActive,
              'disable-reply-box': disableReplyButton,
            }"
          >
            <a href="#" class="reply-tab" @click="setReplyMode">{{
              $t('CONVERSATION.REPLYBOX.REPLY')
            }}</a>
          </li>
          <li
            class="tabs-title is-private"
            :class="{
              'is-active': isPrivate && !isTemplateTabActive,
            }"
          >
            <a href="#" class="private-notes-tab" @click="setPrivateReplyMode">
              {{ $t('CONVERSATION.REPLYBOX.PRIVATE_NOTE') }}
            </a>
          </li>
          <li
            v-if="!disableTemplate"
            class="tabs-title"
            :class="{ 'is-active': isTemplateTabActive }"
          >
            <a href="#" class="templates-tab" @click="setTemplateReplyMode">
              {{ $t('CONVERSATION.REPLYBOX.TEMPLATE') }}
            </a>
          </li>
          <li
            class="tabs-title body-b2 message-length"
            :class="{ 'show-counter': message.length }"
          >
            <a :class="{ 'message-error': isMessageLengthReachingThreshold }">
              {{ characterCountIndicator }}
            </a>
          </li>
        </ul>
        <div
          v-if="
            !showTemplateModal && !isAPrivateEmail && isAnEmail && !isUploading
          "
          class="reply-box-icons email-icons flex-row flex-align"
        >
          <file-upload
            ref="uploadEmail"
            v-model="files"
            :multiple="true"
            :size="4096 * 4096"
            accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
            @input-file="onFileUpload"
          >
            <icons
              name="clip"
              size="semimedium"
              color="darkestgrey"
              title="Upload file(s)"
            />
          </file-upload>
          <span
            v-tooltip="!emailSignature && 'Sign Unavailable'"
            :style="emailSignature && 'cursor: pointer;'"
            :title="
              isEmailSignatureActive ? 'Remove Signature' : 'Add Signature'
            "
            @click="hasEmailSignature = !hasEmailSignature"
          >
            <icons
              name="signature"
              size="semimedium"
              :color="signatureColor"
              :show-title="false"
            />
          </span>
        </div>
        <span v-else-if="isUploading" style="margin-left: 3rem">
          <woot-spinner />
        </span>

        <div v-if="isReplyTabActive" class="mr-right flex-row">
          <woot-tertiary-button
            v-if="isAnEmail && !message"
            custom-class="button clear alert"
            size="small"
            :name="$t('CONVERSATION.REPLYBOX.CANCEL')"
            @click="onCloseReplyBox"
          />
          <span
            v-if="isAnEmail && message"
            style="cursor: pointer"
            class="mr-right"
            @click="showDraftModal = true"
          >
            <icons name="delete" size="semimedium" color="red" />
          </span>
          <woot-dropdown-button
            v-if="showReplyDropdown && !emailForwardingMode"
            size="small"
            :is-inverted="true"
            :name="replyButtonLabel"
            :option-list="replyOptions"
            :button-click="sendMessage"
            :custom-button-style="disableBtnStyle"
            @click="toggleReply"
          />
          <woot-primary-button
            v-else
            size="small"
            :name="
              emailForwardingMode
                ? $t('CONVERSATION.REPLYBOX.FORWARD')
                : $t('CONVERSATION.REPLYBOX.REPLY')
            "
            :custom-style="disableBtnStyle"
            @click="sendMessage"
          />
        </div>
        <div
          v-if="isAPrivateEmail || isPrivateTabActive || isTemplateTabActive"
          :style="isAPrivateEmail && 'width: 100%'"
          class="flex-row flex-align"
          :class="{ 'flex-justify--between': isAPrivateEmail }"
        >
          <woot-tertiary-button
            v-if="isAPrivateEmail"
            custom-class="button clear alert"
            size="small"
            :name="$t('CONVERSATION.REPLYBOX.CANCEL')"
            @click="onCloseReplyBox"
          />
          <woot-primary-button
            v-if="isPrivateTabActive"
            type="button"
            size="small"
            :name="$t('CONVERSATION.REPLYBOX.CREATE')"
            :custom-class="
              `button send-button ${isPrivateTabActive && 'private-button'}`
            "
            :disabled="isReplyButtonDisabled && !files.length"
            @click="sendMessage"
          />
          <woot-primary-button
            v-if="isTemplateTabActive"
            size="small"
            :name="$t('CONVERSATION.REPLYBOX.SEND')"
            custom-class="button send-button"
            :custom-style="
              shopifyRestrictionCheck
                ? {
                    'pointer-events': 'auto',
                    opacity: 0.5,
                  }
                : undefined
            "
            :disabled="!isTemplateReady"
            @click="sendTemplate"
          />
        </div>
      </div>
      <follow-up-modal
        :show="showFollowupModal"
        :on-close="onCancel"
        :on-click="updateStatus"
      />
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

import DropZone from '../../ui/DropZone.vue';
import EmailFile from '../../widgets/conversation/bubble/EmailFile.vue';
import Templates from './Templates';
import FollowUpModal from './FollowUpModal.vue';
import ReplyMessage from 'dashboard/components/widgets/conversation/bubble/ReplyMessage';
import TemplateModal from './TemplateModal';
import ReplyEmailHead from './ReplyEmailHead';
import CannedResponse from './CannedResponse';
import AttachmentPreview from './AttachmentPreview';
import EmojiInput from 'shared/components/emoji/EmojiInput';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import TextEditor from 'shared/components/TextEditor';
import ImproveText from './replyBoxComponents/ImproveText';
import {
  isEscape,
  isEnter,
  hasPressedShift,
  hasPressedAlt,
  hasPressedR,
  hasPressedW,
  hasPressedF,
} from 'shared/helpers/KeyboardHelpers';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import inboxMixin from 'shared/mixins/inboxMixin';
import downloadMixin from 'shared/mixins/downloadMixin';
import alertMixin from 'shared/mixins/alertMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';
import shopifyMixin from 'dashboard/mixins/shopify';
import billingMixin from 'dashboard/mixins/billing';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { removeTrailingSlashesAndSpaces } from 'dashboard/helper/stringUtils';

export default {
  components: {
    FileUpload,
    DropZone,
    EmojiInput,
    CannedResponse,
    ReplyMessage,
    ReplyEmailHead,
    AttachmentPreview,
    ResizableTextArea,
    TextEditor,
    Templates,
    TemplateModal,
    FollowUpModal,
    EmailFile,
    ImproveText,
  },
  mixins: [
    clickaway,
    inboxMixin,
    downloadMixin,
    alertMixin,
    messageFormatterMixin,
    timeMixin,
    billingMixin,
    shopifyMixin,
    mixPanelAnalyticsMixin,
  ],
  props: {
    inReplyTo: {
      type: [String, Number],
      default: '',
    },
    getReplyData: {
      type: [Object, Function],
      default: () => {
        return {
          parentMessageSourceId: null,
          parentMessageContent: '',
          sourceId: null,
          content: '',
          replierName: '',
          recipientName: '',
          messageType: null,
        };
      },
    },
    replyState: {
      type: Boolean,
      required: true,
    },
    isAPost: {
      type: Boolean,
      required: true,
    },
    isAnEmail: {
      type: Boolean,
      default: false,
    },
    disableTemplate: {
      type: Boolean,
      default: false,
    },
    showBanner: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    assignee: {
      type: Object,
      default: null,
    },
    emailForwardingMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropZone: false,
      showDraftModal: false,
      canImproveText: false,
      improvedTextReplaced: false,
      improvedText: '',
      message: '',
      selectedText: '',
      quotedText: '',
      files: [],
      hasEmailSignature: true,
      areEmailsValid: true,
      attachmentPreview: false,
      isReplyTabActive: true,
      isPrivateTabActive: false,
      isTemplateTabActive: false,
      isFocused: false,
      showEmojiPicker: false,
      showCannedResponsesList: false,
      showTemplateList: false,
      showTemplateModal: false,
      conversationPanel: null,
      isUploading: false,
      selectedTemplate: {
        id: null,
        short_code: '',
        media_url: '',
        header: '',
        body: '',
        footer: '',
        buttons: null,
        template_type: 'text',
      },
      templateParameters: [],
      templateMediaUrl: '',
      templateButtonUrl: '',
      toEmails: '',
      bccEmails: '',
      ccEmails: '',
      recipient: '',
      replyButtonText: 'Reply',
      showFollowupModal: false,
      replyOption: null,
      restrictUser: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUserId: 'getCurrentUserID',
      accountDetails: 'accounts/getAccountDetails',
      uiFlags: 'intelligence/getUiFlags',
    }),
    shouldDisplayTextArea() {
      const isModalOrEmail = this.showTemplateModal || this.isAnEmail;
      const isWhatsappAttachmentPreview =
        this.attachmentPreview && this.isWhatsappChannel;
      const isWidgetAttachmentPreview =
        this.attachmentPreview && this.isAWebWidgetInbox;

      return (
        !isModalOrEmail &&
        (isWhatsappAttachmentPreview ||
          !this.attachmentPreview ||
          isWidgetAttachmentPreview)
      );
    },
    replyMessageTheme() {
      if (this.isAFacebookComment || this.isFacebookDm) return 'facebook';
      if (this.isInstagramComment || this.isInstagramDm) return 'instagram';
      return undefined;
    },
    disableAttachmentUpload() {
      const disable = this.isAFacebookComment || this.isInstagramComment;
      return disable;
    },
    attachmentUploadTooltip() {
      return this.disableAttachmentUpload
        ? this.$t('CONVERSATION.FOOTER.ATTACHMENT_NOT_SUPPORTED')
        : null;
    },
    hasTyped() {
      return this.message.trim() !== '';
    },
    shopifyRestrictionCheck() {
      return this.addBillingRestrictions(this.accountDetails);
    },
    disableReplyButton() {
      const cannotShow =
        (this.inPendingPaymentForNonShopify && !this.isEnterprise) ||
        this.showBanner ||
        this.shopifyRestrictionCheck;
      if (cannotShow) this.setPrivateReplyMode();
      return cannotShow;
    },
    replyOptions() {
      return [
        { id: 'reply', name: 'Reply' },
        { id: 'resolve', name: 'Reply and Resolve' },
        { id: 'followup', name: 'Reply and Follow up' },
      ];
    },
    showReplyDropdown() {
      switch (this.status) {
        case 'resolved':
          return false;

        case 'closed':
          return false;

        default:
          return true;
      }
    },
    isPrivate() {
      if (this.currentChat.can_reply) {
        return this.isPrivateTabActive;
      }
      return true;
    },
    isAPrivateEmail() {
      return this.isAnEmail && this.isPrivate;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    messagePlaceHolder() {
      if (this.isAnEmail && !this.isPrivate)
        return this.$t('CONVERSATION.FOOTER.EMAIL_INPUT');
      if (this.isTemplateTabActive)
        return this.$t('CONVERSATION.FOOTER.TEMPLATE_MSG_INPUT');
      if (this.files.length !== 0)
        return this.$t('CONVERSATION.FOOTER.MSG_INPUT_WHEN_ATTACHMENT');
      return this.isPrivate
        ? this.$t('CONVERSATION.FOOTER.PRIVATE_MSG_INPUT')
        : this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isMessageLengthReachingThreshold() {
      return this.message.length > this.maxLength - 40;
    },
    characterCountIndicator() {
      return this.message && `${this.message.length} / ${this.maxLength}`;
    },
    isReplyButtonDisabled() {
      const isMessageEmpty = !this.message.trim().replace(/\n/g, '').length;
      return (
        this.files.length === 0 &&
        (isMessageEmpty ||
          this.message.length === 0 ||
          this.message.length > this.maxLength)
      );
    },
    isEmailSignatureActive() {
      return (
        this.emailSignature &&
        !this.isAPrivateEmail &&
        this.isAnEmail &&
        this.hasEmailSignature
      );
    },
    signatureColor() {
      if (this.isEmailSignatureActive) return 'stroke-green';

      return this.emailSignature ? 'darkestgrey' : 'lightgrey';
    },
    disableBtnStyle() {
      if (
        this.validateEmails() ||
        (this.isReplyButtonDisabled && !this.files.length)
      ) {
        return { opacity: '60%', 'pointer-events': 'none' };
      }
      return {};
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    maxLength() {
      if (this.isPrivate) {
        return MESSAGE_MAX_LENGTH.GENERAL;
      }

      if (this.isAFacebookInbox) {
        return MESSAGE_MAX_LENGTH.FACEBOOK;
      }
      if (this.isATwilioSMSChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_SMS;
      }
      if (this.isAnEmailChannel) {
        return MESSAGE_MAX_LENGTH.EMAIL;
      }
      if (this.isATwitterInbox) {
        if (this.conversationType === 'tweet') {
          return MESSAGE_MAX_LENGTH.TWEET;
        }
      }
      return MESSAGE_MAX_LENGTH.GENERAL;
    },
    replyButtonLabel() {
      if (this.conversationType === 'tweet')
        return this.$t('CONVERSATION.REPLYBOX.TWEET');
      return this.replyButtonText;
    },
    showReplyHead() {
      return !this.isPrivate && this.isAnEmailChannel;
    },
    isTemplateReady() {
      let isReady = true;
      for (let i = 0; i < this.templateParameters.length; i += 1) {
        if (this.templateParameters[i].length === 0) isReady = false;
      }
      if (
        this.selectedTemplate.media_url === '{}' &&
        this.templateMediaUrl === ''
      ) {
        isReady = false;
      }
      if (
        this.selectedTemplate.buttons &&
        this.selectedTemplate.buttons?.type === 'call_to_action' &&
        this.selectedTemplate.buttons?.buttons.find(
          btn => btn?.type === 'dynamic_url'
        ) &&
        this.templateButtonUrl === ''
      ) {
        isReady = false;
      }

      return isReady;
    },
    emailSignature() {
      if (this.currentChat.meta?.assignee?.id) {
        const {
          meta: {
            assignee: { id: agentId },
          },
        } = this.currentChat;
        let { signature, signature_type } = this.$store.getters[
          'agents/getAgent'
        ](agentId);

        if (signature_type === 'markdown')
          signature = this.formatMessage(signature);
        return signature || '';
      }
      return '';
    },
  },
  watch: {
    currentChat(conversation) {
      if (this.isReplyTabActive) {
        const draft = this.getDraft(conversation.id);
        if (!draft) this.message = '';
        else {
          this.message = draft.message;
          this.toEmails = draft.to;
          this.ccEmails = draft.cc;
          this.bccEmails = draft.bcc;
        }
      }

      if (!this.message) this.onCloseReplyBox();
    },
    message(updatedMessage) {
      if (updatedMessage !== this.improvedText)
        this.improvedTextReplaced = false;
      else if (!updatedMessage.trim()) this.improvedTextReplaced = false;
      else this.improvedTextReplaced = true;

      // when improve text component is visible and reply box is emptied by the user
      if (!this.hasTyped) {
        if (this.canImproveText) this.mix_panel_clicked_reject_draft();
        this.canImproveText = false;
      }

      let c_id = this.currentChat.id;

      if (this.isReplyTabActive) this.setDraft(c_id);

      if (this.isTemplateTabActive) {
        this.showTemplateList = true;
        const inboxId = this.inboxId;
        if (updatedMessage.length >= 1) {
          const searchKey = updatedMessage;
          this.$store.dispatch('getTemplate', { inboxId, searchKey });
        } else {
          this.$store.dispatch('getTemplate', { inboxId });
        }
      }

      if (this.isPrivate) return;

      if (this.isAnEmail)
        updatedMessage = this.splitMessageAndQuote(updatedMessage).message;

      let isSlashCommand = updatedMessage[0] === '/';

      const hasNextWord = updatedMessage.includes(' ');
      const isShortCodeActive = isSlashCommand && !hasNextWord;
      if (isShortCodeActive && !this.isTemplateTabActive) {
        this.showCannedResponsesList = true;
        if (this.isTemplateTabActive) return;
        if (updatedMessage.length > 1) {
          const searchKey = updatedMessage.substr(1, updatedMessage.length);
          this.$store.dispatch('getCannedResponse', { searchKey });
        } else {
          this.$store.dispatch('getCannedResponse');
        }
      } else {
        this.showCannedResponsesList = false;
      }
    },
    toEmails() {
      let c_id = this.currentChat.id;

      if (this.isReplyTabActive) this.setDraft(c_id);
    },
    ccEmails() {
      let c_id = this.currentChat.id;

      if (this.isReplyTabActive) this.setDraft(c_id);
    },
    bccEmails() {
      let c_id = this.currentChat.id;

      if (this.isReplyTabActive) this.setDraft(c_id);
    },
  },
  mounted() {
    const draft = this.getDraft(this.currentChat.id);
    if (draft) {
      this.message = draft.message;
      this.toEmails = draft.to;
      this.ccEmails = draft.cc;
      this.bccEmails = draft.bcc;
    }
    this.conversationPanel = document.querySelector('.conversation-panel');
    document.addEventListener('keydown', this.handleKeyEvents);

    this.mouseDownOnImproveTextButton();
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    onCloseImproveText() {
      this.canImproveText = false;
    },
    captureSelectedText() {
      const textarea = this.$refs.messageInput;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      return { selectionStart, selectionEnd };
    },
    onTextImproved() {
      this.selectedText = '';
    },
    onReplaceText(newText) {
      this.focusTextArea();
      this.onCloseImproveText();
      this.improvedTextReplaced = true;
      this.mix_panel_clicked_select_draft();

      if (!this.selectedText) {
        this.improvedText = newText;
        this.message = newText;
        return;
      }

      const { selectionStart, selectionEnd } = this.captureSelectedText();

      const searchText = this.message.substring(selectionStart, selectionEnd);

      if (searchText === this.selectedText) {
        this.message =
          this.message.slice(0, selectionStart) +
          newText +
          this.message.slice(selectionEnd);
        this.improvedText = this.message;
      } else throw new Error('The selected text is not ' + this.selectedText);
    },
    mouseDownOnImproveTextButton() {
      if (this.$refs?.improveTextButton) {
        this.$refs.improveTextButton.addEventListener(
          'mousedown',
          this.handleImproveButtonClick
        );
      }
    },
    handleImproveButtonClick(event) {
      event.preventDefault();
      this.focusTextArea();
    },
    focusTextArea() {
      this.$refs.messageInput.focus();
    },
    improveText() {
      if (!this.hasTyped) return;
      this.mix_panel_clicked_improve_draft();
      this.canImproveText = false;
      this.$nextTick(() => {
        this.canImproveText = true;
      });
    },
    toggleReply(val) {
      if (val.id === 'reply') this.replyButtonText = val.name;
      else if (val.id === 'resolve') {
        this.replyOption = 'resolved';
        this.updateStatus();
      } else if (val.id === 'followup') {
        this.replyOption = 'followup';
        this.showFollowupModal = true;
      }
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.hideEmojiPicker();
        this.hideCannedResponse();
        this.hideTemplate();
      } else if (isEnter(e)) {
        if (
          !hasPressedShift(e) &&
          !this.isTemplateTabActive &&
          !this.isAnEmail &&
          this.isFocused
        ) {
          e.preventDefault();
          this.sendMessage();
        } else if (this.isTemplateTabActive && this.isTemplateReady) {
          e.preventDefault();
          this.sendTemplate();
        }
        return;
      }
      if (hasPressedAlt(e)) {
        if (hasPressedR(e)) {
          e.preventDefault();
          this.replyOption = 'resolved';
          this.updateStatus();
        } else if (hasPressedF(e)) {
          e.preventDefault();
          this.replyOption = 'followup';
          this.showFollowupModal = true;
        } else if (hasPressedW(e)) {
          e.preventDefault();
          this.replyOption = 'waiting';
          this.showFollowupModal = true;
        }
      }
    },
    timeInMinutes({ HH = '00', mm = '00' }) {
      return parseInt(HH, 10) * 60 + parseInt(mm, 10);
    },
    validateEmails() {
      return (
        this.isAnEmail &&
        ((!this.ccEmails && !this.bccEmails && !this.toEmails) ||
          !this.areEmailsValid)
      );
    },
    updateStatus(alert_delay = { HH: '00', mm: '00' }) {
      this.sendMessage();
      let alertTime = this.timeInMinutes(alert_delay);
      let d = {
        conversationId: this.currentChat.id,
        conversationStatus: this.replyOption,
        alertDelay: alertTime,
      };
      this.$store.dispatch('toggleStatus', d).then(data => {
        let isSuccess = data?.data?.payload?.success;
        if (isSuccess) {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'), 'success');
        } else {
          this.showAlert(this.$t('CONVERSATION.UNEXPECTED'), 'error');
        }
        this.onCancel();
      });
    },
    onCancel() {
      this.showFollowupModal = false;
    },
    deleteDraft() {
      this.setDraft(this.currentChat.id);
      this.message = '';
      this.showDraftModal = false;
      setTimeout(() => {
        this.onCloseReplyBox();
      }, 100);
    },
    onCloseReplyBox() {
      this.$emit('onCloseReplyBox');
    },
    scrollToBottom() {
      this.conversationPanel.scrollTo({
        top: this.conversationPanel.scrollHeight,
        behavior: 'smooth',
      });
    },
    uploadAttachments(files) {
      this.$refs.uploadEmail.add(files);
    },
    modifyReplyDataForReplyBox(replyData) {
      const { payload } = replyData();
      const data = { ...payload };

      let { instagramMention } = data;

      if (this.replyState && !this.message.includes(instagramMention))
        this.message = instagramMention;

      data.parentMessageSourceId = data.sourceId;
      data.parentMessageContent = data.content;
      data.content = '';
      data.messageType = '';

      return () => ({ payload: data });
    },
    async sendTemplate() {
      if (this.shopifyRestrictionCheck) {
        this.showAlert(this.$t('CONVERSATION.TEMPLATE_RESTRICTED'), 'info');
        return;
      }
      if (this.showTemplateModal) {
        try {
          const selectedParameters = [...this.templateParameters];
          if (
            this.selectedTemplate.has_media &&
            this.selectedTemplate.media_url === '{}'
          ) {
            selectedParameters.unshift(this.templateMediaUrl);
          }
          if (this.selectedTemplate.has_dynamic_button) {
            selectedParameters.push(this.templateButtonUrl);
          }

          const messagePayload = {
            conversationId: this.currentChat.id,
            message: '',
            private: false,
            isTemplate: true,
            templateId: this.selectedTemplate.id,
            parameters: selectedParameters,
          };

          this.$store.dispatch('sendMessage', messagePayload);
          this.scrollToMessage();
          this.templateParameters = [];
          this.templateMediaUrl = '';
          this.templateButtonUrl = '';
        } catch (error) {
          // handle error
        }
      }
      this.closeTemplateModal();
      this.showTemplateList = false;
    },
    splitMessageAndQuote(message) {
      let [content, quoted] = message.split('*...*');
      let quotedText = '';

      if (message.length > 1) {
        message = removeTrailingSlashesAndSpaces(content);

        quotedText = quoted;
      } else quotedText = '';

      return {
        message: message,
        quoted: quotedText,
      };
    },
    async sendMessage() {
      this.showDropZone = false;
      const canSendParentMessageId = this.replyState;
      this.$emit('toggleReply', false);

      if (this.files.length) this.isUploading = true;

      if (this.isReplyButtonDisabled) return;

      if (this.isAnEmail) {
        const { message, quoted } = this.splitMessageAndQuote(this.message);
        this.message = message;
        this.quotedText = quoted;
      }

      const newMessage = {
        message: this.message.trim(),
        quotedText: this.quotedText,
        ccEmails: this.ccEmails,
        bccEmails: this.bccEmails,
        toEmails: this.toEmails,
      };
      if (this.improvedTextReplaced) {
        this.mix_panel_clicked_reply_draft();
        this.improvedTextReplaced = false;
        this.improvedText = '';
      }

      if (!this.showCannedResponsesList) {
        this.clearMessage();
        if (!this.isAPost) {
          this.$emit('toggleReply', false);
        }

        try {
          const messagePayload = {
            conversationId: this.currentChat.id,
            message: newMessage.message,
            private: this.isPrivate,
          };

          if (this.emailForwardingMode)
            messagePayload.messageType = 'forwarded_mail';

          if (this.files.length) {
            messagePayload.files = [];
            this.files.forEach(attachment =>
              messagePayload.files.push(attachment.file)
            );
          }

          if (newMessage.toEmails) {
            messagePayload.toEmails = newMessage.toEmails;
          }

          if (newMessage.ccEmails) {
            messagePayload.ccEmails = newMessage.ccEmails;
          }

          if (newMessage.bccEmails) {
            messagePayload.bccEmails = newMessage.bccEmails;
          }

          if (newMessage.quotedText)
            messagePayload.quotedText = newMessage.quotedText;

          if (
            typeof this.getReplyData === 'function' ||
            !!this.getReplyData.sourceId
          ) {
            const {
              payload: { sourceId },
            } = this.getReplyData();

            if (sourceId && canSendParentMessageId) {
              messagePayload.parentMessageId = sourceId;
            }
          }

          messagePayload.hasEmailSignature = this.isEmailSignatureActive
            ? 1
            : 0;

          await this.$store.dispatch('sendMessage', messagePayload).then(() => {
            this.message = '';
            this.setDraft(messagePayload.conversationId);
            this.isUploading = false;
            this.onCloseReplyBox();
            this.attachmentPreview = false;
            this.files = [];
          });
          this.scrollToMessage();
        } catch (error) {
          this.isUploading = false;
          this.attachmentPreview = false;
          this.files = [];
        }
        this.hideEmojiPicker();
      }
    },
    replaceText(message) {
      setTimeout(() => {
        this.message = message + this.quotedText;
      }, 100);
    },
    setPrivateReplyMode() {
      this.isReplyTabActive = false;
      this.isPrivateTabActive = true;
      this.isTemplateTabActive = false;
      this.showTemplateModal = false;
      this.showTemplateList = false;
      this.clearMessage();
      if (this.$refs.messageInput) {
        this.focusTextArea();
      }
    },
    setTemplateReplyMode() {
      this.message = '';
      this.isReplyTabActive = false;
      this.isTemplateTabActive = true;
      this.isPrivateTabActive = false;
      this.showTemplateModal = false;
      if (this.$refs.messageInput) {
        this.focusTextArea();
      }
      this.showTemplateList = true;
      let inboxId = this.inboxId;
      this.$store.dispatch('getTemplate', { inboxId });
    },
    openTemplateModal(template) {
      this.selectedTemplate = template;
      let splitedLength = this.selectedTemplate.body.split('{}').length;
      if (splitedLength === 1) {
        this.templateParameters = [];
      } else {
        this.templateParameters = Array(splitedLength - 1)
          .join('.')
          .split('.');
      }
      if (this.selectedTemplate.has_media) {
        this.templateMediaUrl = this.selectedTemplate.media_url;
      }

      this.showTemplateModal = true;
    },
    closeTemplateModal() {
      this.showTemplateModal = false;
      this.showTemplateList = true;
      this.templateParameters = [];
      this.templateMediaUrl = '';
      this.templateButtonUrl = '';
      this.clearMessage();
    },
    setTemplateParameter(val, index) {
      this.$set(this.templateParameters, index, val);
    },
    setReplyMode() {
      this.isReplyTabActive = true;
      this.isPrivateTabActive = false;
      this.isTemplateTabActive = false;
      this.showTemplateModal = false;
      this.showTemplateList = false;
      this.clearMessage();
      if (this.$refs.messageInput) this.focusTextArea();
    },
    emojiOnClick(emoji) {
      this.mix_panel_sent_ticket_emoji();
      this.message = `${this.message}${emoji} `;
    },
    clearMessage() {
      this.message = '';
      this.ccEmails = '';
      this.bccEmails = '';
    },
    toggleEmojiPicker() {
      this.showDropZone = false;
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideCannedResponse() {
      this.showCannedResponsesList = false;
    },
    hideTemplate() {
      this.showTemplateList = false;
    },
    onBlur() {
      this.isFocused = false;
      this.toggleTyping('off');
    },
    onFocus() {
      this.isFocused = true;
      this.toggleTyping('on');
    },
    toggleTyping(status) {
      if (this.isAWebWidgetInbox && !this.isPrivate) {
        const conversationId = this.currentChat.id;
        this.$store.dispatch('conversationTypingStatus/toggleTyping', {
          status,
          conversationId,
        });
      }
    },
    onDropped(e) {
      if (e.dataTransfer.files.length === 0) this.showDropZone = false;
    },
    uploadedFileData(files, newFile, oldFile) {
      this.files = files;
      this.onFileUpload(newFile, oldFile);
    },
    onFileUpload(newFile, oldFile) {
      this.addImagePreviewUrl(newFile, oldFile);
      if (newFile && !oldFile) {
        this.attachmentPreview = true;
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.attachmentPreview) this.scrollToBottom();
          });
        }, 200);
      }
    },
    clearAttachments(fileId) {
      if (!fileId) this.files = [];
      this.files = this.files.filter(file => file.id !== fileId);
      if (this.files.length === 0) {
        this.attachmentPreview = false;
        this.showDropZone = false;
      }
    },
    sendAttachment(file) {
      if (!file) {
        return;
      }
      this.isUploading = true;
      this.$store
        .dispatch('sendAttachment', [
          this.currentChat.id,
          { file: file.file, isPrivate: this.isPrivate },
        ])
        .then(() => {
          this.isUploading = false;
          this.attachmentPreview = false;
          this.files = [];
          this.scrollToMessage();
        })
        .catch(() => {
          this.isUploading = false;
          this.attachmentPreview = false;
          this.files = [];
          this.scrollToMessage();
        });
    },

    getDraft(cid) {
      this.validateEmails();
      return JSON.parse(localStorage.getItem('draftMessages'))?.[cid] || '';
    },
    setDraft(cid) {
      const draftMessages =
        JSON.parse(localStorage.getItem('draftMessages')) || {};
      const conversationId = cid.toString();

      if (this.message.trim() === '') {
        // delete draftMessage;
        let updatedDraftMessages = Object.keys(draftMessages).reduce(
          (accumulator, key) => {
            // Copy all except cid
            if (key !== conversationId) {
              accumulator[key] = draftMessages[key];
            }
            return accumulator;
          },
          {}
        );
        localStorage.setItem(
          'draftMessages',
          JSON.stringify(updatedDraftMessages)
        );
      } else {
        draftMessages[conversationId] = {
          message: this.message,
          to: this.toEmails,
          cc: this.ccEmails,
          bcc: this.bccEmails,
        };
        localStorage.setItem('draftMessages', JSON.stringify(draftMessages));
      }
    },
    scrollToMessage() {
      this.$emit('scrollToMessage');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.modal-content-wrap {
  width: 100%;

  .button-container {
    padding: $zero $space-normal $space-normal;
    gap: $space-small;
  }
}

.mr-right {
  margin-right: $space-two;
}

.disable-reply-box {
  display: none;
}

.reply-box-active {
  @include elevation-12dp;
}

.private-note-head {
  padding-bottom: $space-slab;
  border-bottom: 1px solid $neutral-grey-400;
}

.send-button {
  margin: $zero $space-one $space-one !important;
  border-radius: $border-radius-smaller;
  text-transform: uppercase;
  padding: $space-one;
  font-weight: $font-weight-black;
  font-size: $font-size-mini !important;
}

.reply-box-icons {
  .file-uploads::v-deep label {
    cursor: pointer;
  }
}

.email-icons {
  gap: $space-one;
}

.uploaded-files-container {
  gap: $space-one $space-normal;
  flex-wrap: wrap;
  margin-top: $space-normal;
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
