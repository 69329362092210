import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AccountAPI from '../../api/account';
import { getCurrencySymbol } from '../../helper/currencyHandler';
import { accountIdFromPathname } from 'dashboard/helper/URLHelper';
import router from 'dashboard/routes';

function getAccountsFromLocalStorage() {
  const accounts = localStorage.getItem('userAccounts');
  return JSON.parse(accounts || '[]');
}

function getAccountFeatureFlag(featureKey) {
  const { pathname } = window.location;
  const accountId = accountIdFromPathname(pathname);
  const accounts = getAccountsFromLocalStorage();

  if (!accounts.length) return false;

  const accountFeatures = accounts.find(record => record.id === accountId)
    ?.features;

  if (!accountFeatures) return false;

  return accountFeatures[featureKey];
}

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isFetchingPaymentLink: false,
    isFetchingBotConfigs: false,
    isUpdating: false,
    isAllTabHidden: false,
    isQueuedTabHidden: false,
    isBotTicketHidden: false,
    isTaggingEnforced: false,
    isHideOutOfStock: false,
  },
};

export const getters = {
  getAccount: $state => id => {
    return $state.records.find(record => record.id === Number(id));
  },
  getAccountDetails: $state => {
    return $state.records[0];
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAccountCurrency: $state => id => {
    try {
      const account = $state.records.find(record => record.id === Number(id));
      return getCurrencySymbol(account.currency);
    } catch {
      return '';
    }
  },
  getHideProductLinksFlag() {
    return getAccountFeatureFlag('hide_product_links');
  },
  // if deep shopify access or not
  getDeepShopifyEnabledFlag() {
    return getAccountFeatureFlag('deep_shopify');
  },
  getCustomConversationViewsEnabledFlag() {
    return getAccountFeatureFlag('use_latest_conversation_finder');
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetchingItem: true });

    try {
      const response = await AccountAPI.get();
      commit(types.default.ADD_ACCOUNT, response.data);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 404) {
        router.replace({
          name: 'error',
          params: {
            errorTitle: 'Account Not Found',
            errorSubtitle: 'The requested account could not be found.',
          },
        });
      }
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }

    return {};
  },
  getBotConfigs: async ({ commit }, accountId) => {
    try {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingBotConfigs: true,
      });
      const { data } = await AccountAPI.getBotConfigs();
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingBotConfigs: false,
      });
      commit(types.default.EDIT_ACCOUNT_WITHOUT_OVERRIDING, {
        id: accountId,
        bot_csat_configs: { ...data.bot_csat_configs },
      });
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingBotConfigs: false,
      });
      throw new Error(error);
    }
  },
  fetchPaymentLink: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, {
      isFetchingPaymentLink: true,
    });
    try {
      const response = await AccountAPI.fetchPaymentLink();
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingPaymentLink: false,
      });
      return response.data;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingPaymentLink: false,
      });
      throw error;
    }
  },
  update: async ({ commit }, data) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AccountAPI.update(data);
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
      throw new Error(error);
    }
  },
  create: async ({ commit }, accountInfo) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: true });
    try {
      const response = await AccountAPI.createAccount(accountInfo);
      const account_id = response.data.data.account_id;
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      return account_id;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  setUiVisibility: async ({ commit }, data) => {
    commit(types.default.SET_UI_VISIBILITY_FLAGS, data);
  },
};

export const mutations = {
  [types.default.SET_ACCOUNT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_UI_VISIBILITY_FLAGS]($state, data) {
    const {
      hide_all_tab,
      hide_queued_tab,
      hide_bot_conversations,
      enforce_tagging,
      hide_out_of_stock,
    } = data;
    $state.uiFlags = {
      ...$state.uiFlags,
      isAllTabHidden: hide_all_tab,
      isQueuedTabHidden: hide_queued_tab,
      isBotTicketHidden: hide_bot_conversations,
      isTaggingEnforced: enforce_tagging,
      isHideOutOfStock: hide_out_of_stock,
    };
  },
  [types.default.ADD_ACCOUNT]: MutationHelpers.setSingleRecord,
  [types.default.EDIT_ACCOUNT]: MutationHelpers.update,
  [types.default.EDIT_ACCOUNT_WITHOUT_OVERRIDING]:
    MutationHelpers.updateAttributes,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
