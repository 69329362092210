<template>
  <div class="lime-card-1dp conv-header">
    <div class="header-actions-wrap">
      <div class="multiselect-box">
        <div @click="showContactModal = true">
          <woot-avatar
            :username="currentContact.name"
            color="#fff"
            background-color="#1B83AC"
          />
        </div>
        <div style="margin-left: 1rem">
          <div
            class="title-h2 text-dark conversation--user"
            @click="showContactModal = true"
          >
            {{ currentContact.name }}
          </div>

          <span class="flex-row-center flex-justify--start flex-wrap">
            <div class="flex-row">
              <icons
                :name="computedInboxClass.name"
                :color="`${computedInboxClass.color} evenodd`"
                size="normal"
              />
              <span
                v-if="accountId !== chat.account_id"
                class="inbox-name text-truncate"
                @click="showContactModal = true"
              >
                {{ companyName(chat.account_id, currentUser) }}
              </span>
              <span
                v-else
                class="inbox-name body-b3 text-light text-truncate"
                :class="`${computedInboxClass.color}-color`"
                @click="showContactModal = true"
              >
                {{ inboxInfo.name }}
              </span>
            </div>
            <span
              v-if="contact.phone_number"
              class="body-b3 text-dark contact-phone-number"
              @click="onCopy(contact.phone_number)"
            >
              {{ contact.phone_number }}
            </span>
          </span>
        </div>
      </div>

      <div class="actions-container flex-row flex-align flex-justify">
        <span
          v-tooltip.bottom="tooltipMsg"
          :class="{
            'phone-icon': isCallFeatureAvailable,
            'phone-icon--disabled': !isCallFeatureAvailable,
            jiggle: callStatesFlags.showCallPopup,
          }"
          @click="onShowCallPopup"
        >
          <icons
            :name="
              isCallInProgress || callStatesFlags.showCallNotification
                ? 'phone'
                : 'call'
            "
            :title="isCallInProgress ? 'call in progress' : 'call'"
            :color="callIconColor"
            size="medium"
          />
        </span>
        <more-actions :conversation-id="currentChat.id" />
        <fav-button
          :is-conv-fav="isConvFav"
          icon="star"
          icon-active-color="warningyellow"
          icon-inactive-color="lightgrey"
          icon-view-size="0 0 20 20"
          icon-size="medium"
          @click="toggleFav"
        />
        <div
          v-if="isLoading"
          class="spinner-container flex-row flex-align flex-justify"
        >
          <spinner size="large" />
        </div>
        <div v-else style="width: 12rem" class="multiselect-box">
          <woot-single-selector
            v-if="currentStatus !== 'Open'"
            id="conversation-status--dropdown"
            variant="tertiary"
            size="small"
            :custom-button="true"
            icon-size-prop="medium"
            :option-list="getActionList"
            :default-option="currentStatus"
            :show-option-name="false"
            @click="toggleStatus"
          >
            <woot-badge
              v-if="currentStatus !== 'Open'"
              :is-status="true"
              :status="currentStatus"
            />
            <span style="margin-left: 0.8rem">
              <icons name="chevronDown" size="medium" color="green" />
            </span>
          </woot-single-selector>
          <woot-dropdown-button
            v-if="currentStatus === 'Open'"
            id="conversation-status-selector"
            :name="resolvedState"
            :option-list="getActionList"
            :button-click="changeToResolved"
            :custom-style="{ 'min-width': '15rem' }"
            @click="toggleStatus"
          />
        </div>
      </div>
    </div>
    <follow-up-modal
      :show="showAutoClosePopUp"
      :status="newStatus"
      :on-close="onCancel"
      :on-click="updateStatus"
    />
    <woot-modal
      :show.sync="showContactModal"
      :on-close="onCancelContactModal"
      :show-close="false"
      :has-transition="false"
      :custom-style="{ 'margin-top': '3rem', 'margin-left': '15rem' }"
      :modal-position="{ 'align-items': 'flex-start' }"
    >
      <contact-info
        :contact="contact"
        :inbox-info="inboxInfo"
        @showEdit="toggleEditModal"
      />
    </woot-modal>

    <edit-contact
      v-if="showEditModal"
      :show="showEditModal"
      :contact="contact"
      @cancel="toggleEditModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import copy from 'copy-text-to-clipboard';

import MoreActions from './MoreActions';
import FollowUpModal from './FollowUpModal';
import ContactInfo from './ContactInfoModal';
import EditContact from './EditContactModal';
import Spinner from 'shared/components/Spinner';
import FavButton from '../../buttons/FavButton';

import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';
import callMixin from 'shared/mixins/callMixin';
import companyMixin from 'dashboard/mixins/company';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import labelsMixin from 'shared/mixins/labelsMixin';
import { statusType } from 'dashboard/constants';

export default {
  components: {
    MoreActions,
    FollowUpModal,
    ContactInfo,
    EditContact,
    FavButton,
    Spinner,
  },
  mixins: [
    googleAnalyticsMixin,
    alertMixin,
    inboxMixin,
    callMixin,
    companyMixin,
    labelsMixin,
  ],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      conversationStatus: null,
      showAutoClosePopUp: false,
      newStatus: null,
      resolveState: 'Resolve',
      isLoading: false,
      showContactModal: false,
      showEditModal: false,
    };
  },

  computed: {
    ...mapGetters({
      agents: 'agents/getVerifiedAgents',
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      callStatesFlags: 'getCallStatesUiFlags',
      currentCallState: 'getCurrentCallState',
      knowlarityInboxes: 'inboxes/getKnowlarityInboxes',
      accountLabels: 'labels/getLabelsWithoutGroups',
      uiFlags: 'accounts/getUIFlags',
    }),
    activeLabels() {
      const savedLabels = this.$store.getters[
        'conversationLabels/getConversationLabels'
      ](this.currentChat.id);

      return this.filterClientLabels(savedLabels);
    },
    isCallFeatureAvailable() {
      return !!this.knowlarityInboxes.find(inbox =>
        this.currentUser.call_function.includes(inbox.id)
      );
    },
    tooltipMsg() {
      if (!this.knowlarityInboxes.length)
        return 'Setup Knowlarity Integration to use this feature.';
      if (!this.isCallFeatureAvailable)
        return 'You do not have enough permissions to perform this action. Please contact an administrator';
      return null;
    },
    callIconColor() {
      if (!this.isCallFeatureAvailable) return 'textlightgrey';
      if (this.callStatesFlags.showCallPopup) return 'green';
      return 'darkestgrey';
    },
    contact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta?.sender?.id
      );
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },
    inboxInfo() {
      const stateInbox = this.$store.getters['inboxes/getInbox'](
        this.chat.inbox_id
      );
      return stateInbox;
    },
    computedInboxClass() {
      return this.getInboxClassByType(
        this.inboxInfo?.channel_type,
        this.inboxInfo?.phone_number,
        '',
        this.inboxInfo?.instagram_add_on
      );
    },
    chatMetadata() {
      return this.chat.meta;
    },
    isConvNew() {
      return this.chat.is_new;
    },
    agentList() {
      let agents = this.agents.map(agent => {
        return {
          id: agent.id,
          name: agent.name,
          role: agent.role,
        };
      });
      return [
        {
          confirmed: true,
          name: 'None',
          id: 0,
          role: 'agent',
          account_id: 0,
          email: 'None',
          icon: '',
        },
        ...agents,
      ];
    },
    getActionList() {
      return this.$t('CHAT_LIST.CHAT_STATUS_ITEMS').reduce((filtered, item) => {
        if (['bot', 'closed', 'outbound'].includes(item.VALUE)) {
          filtered.push({
            id: item.VALUE,
            name: item.NAME,
            disabled: true,
          });
        } else {
          filtered.push({
            id: item.VALUE,
            name: item.NAME,
          });
        }

        return filtered;
      }, []);
    },
    isConvFav() {
      return this.chat.starred;
    },
    currentStatus() {
      return this.findStatusObject(this.chat.status).name;
    },
    resolvedState() {
      return this.resolveState;
    },
    eventName() {
      return this.currentCallState.eventName;
    },
    isCallInProgress() {
      return this.eventName !== 'HANGUP' && this.eventName !== '';
    },
  },
  watch: {
    chat(newChat) {
      if (newChat.status === 'open') this.resolveState = 'Resolve';
    },
  },
  methods: {
    onShowCallPopup() {
      if (
        !this.isCallFeatureAvailable ||
        this.callStatesFlags.showCallNotification
      )
        return;
      if (
        !this.callStatesFlags.showCallPopup &&
        (this.eventName === 'HANGUP' || this.eventName === '')
      ) {
        const {
          phone_number: agentNumber,
          role: agentRole,
          available_name: agentName,
        } = this.currentUser;

        const {
          meta: {
            sender: { phone_number: customerNumber, name: customerName },
          },
        } = this.currentChat;

        this.createCallInstance({
          agentNumber,
          agentRole,
          agentName,
          customerNumber,
          customerName,
          chat: this.currentChat,
          activeInbox: this.activeInbox,
        });
      }
      this.$store.dispatch(
        'showCallPopup',
        !this.callStatesFlags.showCallPopup
      );
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
      this.showContactModal = !this.showContactModal;
    },
    highlightEnforceTag() {
      this.showAlert(
        'Please Add labels/tags before resolving the conversation',
        'error'
      );
      const targetElement = document.getElementById('add-tags-accordion');

      targetElement.classList.add('highlight-labelbox');

      // Revert the DOM changes after 2 seconds
      setTimeout(() => {
        targetElement.classList.remove('highlight-labelbox');
      }, 2000);
    },
    changeToResolved() {
      if (!this.activeLabels.length && this.uiFlags.isTaggingEnforced) {
        this.highlightEnforceTag();
        return;
      }
      this.logQuickResolve('resolved');

      this.conversationStatus = { id: 'resolved', name: 'Resolved' };
      this.isLoading = true;
      this.updateStatus();
    },
    toggleStatus(val) {
      if (
        val.id === 'resolved' &&
        !this.activeLabels.length &&
        this.uiFlags.isTaggingEnforced
      ) {
        this.highlightEnforceTag();
        return;
      }

      if (val.id === 'open') this.resolveState = 'Resolve';
      this.logConversationStatusChange(val.id);
      this.conversationStatus = val;
      this.isLoading = true;
      if (val.id === 'followup' || val.id === 'waiting') {
        this.newStatus = val.id;
        this.showAutoClosePopUp = true;
      } else if (val.id === 'open' || val.id === 'resolved') {
        this.updateStatus();
      }
    },
    timeInMinutes({ HH = '00', mm = '00' }) {
      // eslint-disable-next-line radix
      return parseInt(HH) * 60 + parseInt(mm);
    },
    async updateStatus(
      alertDelay = { HH: '00', mm: '00' },
      nextStatus = statusType.OPEN
    ) {
      alertDelay = this.timeInMinutes(alertDelay);
      const params = {
        conversationId: this.chat.id,
        conversationStatus: this.conversationStatus.id,
        alertDelay,
        nextStatus,
      };
      try {
        await this.$store.dispatch('toggleStatus', params);
      } catch (error) {
        this.isLoading = false;
        this.showAlert(
          error.message || this.$t('CONVERSATION.UNEXPECTED'),
          'error'
        );
      } finally {
        this.onCancel();
      }
    },
    toggleFav() {
      this.logConversationStarred();
      this.$store.dispatch('starConversation', this.chat.id).then(() => {
        if (this.isConvFav)
          this.showAlert(this.$t('CONVERSATION.STAR'), 'success');
        else this.showAlert(this.$t('CONVERSATION.UNSTAR'), 'info');
      });
    },
    onCancel() {
      this.conversationStatus = this.findStatusObject(this.chat.status);
      this.showAutoClosePopUp = false;
      this.isLoading = false;
    },
    onCancelContactModal() {
      this.showContactModal = false;
    },
    findStatusObject(status) {
      return this.getActionList.filter(obj => {
        return obj.id === status;
      })[0];
    },
    onCopy(value) {
      copy(value);
      this.showAlert(this.$t('CONVERSATION.PHONE_NO_COPY'), 'info');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.spinner-container {
  width: $space-slab * 10;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions-container {
  gap: $space-two;

  .phone-icon {
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    ::v-deep .icon {
      transition: fill 0.2s ease-out;

      &:hover {
        fill: $pg-1;
      }
    }
  }
}
</style>
