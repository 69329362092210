import { default as _agentMgmt } from './agentMgmt.json';
import { default as _billing } from './billing.json';
import { default as _labelsMgmt } from './labelsMgmt.json';
import { default as _cannedMgmt } from './cannedMgmt.json';
import { default as _chatlist } from './chatlist.json';
import { default as _contact } from './contact.json';
import { default as _conversation } from './conversation.json';
import { default as _inboxMgmt } from './inboxMgmt.json';
import { default as _login } from './login.json';
import { default as _analytics } from './analytics.json';
import { default as _resetPassword } from './resetPassword.json';
import { default as _errorPage } from './errorPage.json';
import { default as _setNewPassword } from './setNewPassword.json';
import { default as _settings } from './settings.json';
import { default as _signup } from './signup.json';
import { default as _release } from './release.json';
import { default as _integrations } from './integrations.json';
import { default as _generalSettings } from './generalSettings.json';
import { default as _download } from './download.json';
import { default as _broadcast } from './broadcast.json';
import { default as _template } from './template.json';
import { default as _storeNotifications } from './storeNotifications.json';
import { default as _productsMgmt } from './productsMgmt.json';
import { default as _teamsSettings } from './teamsSettings.json';
import { default as _csatMgmtMgmt } from './csatMgmt.json';
import { default as _cartMgmt } from './cart.json';
import { default as _orderMgmt } from './order.json';
import { default as _deepShopify } from './deepShopify.json';
import { default as _automation } from './automation.json';
import { default as _advancedFilters } from './advancedFilters.json';
import { default as _onboarding } from './onboarding.json';
import { default as _createTicket } from './createTicket.json';
import { default as _ticketAssignment } from './ticketAssignment.json';
import { default as _customTicketFields } from './customTicketFields.json';
import { default as _sla } from './sla.json';

export default {
  ..._advancedFilters,
  ..._agentMgmt,
  ..._billing,
  ..._cannedMgmt,
  ..._chatlist,
  ..._contact,
  ..._conversation,
  ..._inboxMgmt,
  ..._login,
  ..._analytics,
  ..._labelsMgmt,
  ..._resetPassword,
  ..._errorPage,
  ..._setNewPassword,
  ..._settings,
  ..._signup,
  ..._release,
  ..._integrations,
  ..._generalSettings,
  ..._download,
  ..._broadcast,
  ..._template,
  ..._storeNotifications,
  ..._productsMgmt,
  ..._teamsSettings,
  ..._csatMgmtMgmt,
  ..._cartMgmt,
  ..._orderMgmt,
  ..._deepShopify,
  ..._automation,
  ..._onboarding,
  ..._createTicket,
  ..._ticketAssignment,
  ..._customTicketFields,
  ..._sla,
};
