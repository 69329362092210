<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t(`INBOX_MGMT.ADD.${channelName.toUpperCase()}.TITLE`)"
      :header-content="$t(`INBOX_MGMT.ADD.${channelName.toUpperCase()}.DESC`)"
    />
    <div class="row channels">
      <channel-item
        v-for="channel in computedChannels"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth(channel.key)"
      />
    </div>
    <div class="medium-12 columns settings-sub-menu-bottom-nav">
      <woot-base-button
        variant="secondary"
        @click.prevent="() => $router.go(-1)"
      >
        Back
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import ChannelItem from 'dashboard/components/widgets/ChannelItem';

import ChannelListMixin from 'dashboard/mixins/channelList';

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  mixins: [ChannelListMixin],
  props: {
    channelName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      channelList: [
        { key: 'knowlarity', name: 'Voice (Knowlarity)', type: 'voice' },
        { key: 'three_sixty_dialog', name: '360 Dialog', type: 'whatsapp' },
        { key: 'maytapi', name: 'Maytapi', type: 'whatsapp' },
        { key: 'freshchat', name: 'Freshchat', type: 'whatsapp' },
        { key: 'twilio', name: 'Twilio', type: 'whatsapp' },
        { key: 'chat_API', name: 'chat_API', type: 'whatsapp' },
        { key: 'acl', name: 'ACL', type: 'whatsapp' },
        { key: 'zoko', name: 'Zoko', type: 'whatsapp' },
        { key: 'gupshup', name: 'Gupshup', type: 'whatsapp' },
        {
          key: 'whatsapp_cloud_API',
          name: 'Whatsapp Cloud API',
          type: 'whatsapp',
        },
        { key: 'wati', name: 'Wati', type: 'whatsapp' },
        { key: 'value_first', name: 'Value First', type: 'whatsapp' },
        {
          key: 'gupshup_enterprise',
          name: 'Gupshup Enterprise',
          type: 'whatsapp',
        },
        {
          key: 'ics',
          name: 'ICS',
          type: 'whatsapp',
        },
        {
          key: 'infobip',
          name: 'INFOBIP',
          type: 'whatsapp',
        },
      ],
    };
  },
  computed: {
    computedChannels() {
      switch (this.channelName) {
        case 'whatsapp_channels':
          return this.whatsappChannels;

        case 'voice_channels':
          return this.voiceChannels;

        default:
          return [];
      }
    },
    whatsappChannels() {
      return this.channelList.filter(item => item.type === 'whatsapp');
    },
    voiceChannels() {
      return this.channelList.filter(item => item.type === 'voice');
    },
  },
};
</script>
