<template>
  <div>
    <div class="conversations-sidebar-header">
      <div class="chat-list__top">
        <h1 class="page-title text-truncate title-h1 text-dark">
          <span class="mg-right--smaller">
            <img src="~/assets/images/ticket.svg" />
          </span>
          {{ $t('CHAT_LIST.TAB_HEADING') }}
        </h1>
      </div>
      <div class="search-container">
        <woot-base-button
          variant="secondary"
          icon-size="semimedium"
          front-icon="search"
          icon-color="lightgrey"
          class="search-button full-width"
          @click="onShowSearchModal"
        >
          <span
            class="body-b3 text-dark line-clamp-1"
            v-text="savedSearchMeta.query"
          />
        </woot-base-button>
        <woot-base-button
          class="close-icon"
          size="small"
          tag="span"
          variant="secondary-danger"
          @click="onClearSearch"
        >
          <icons
            name="closeRound"
            color="red"
            size="normal"
            :show-title="false"
          />
        </woot-base-button>
      </div>
    </div>
    <div class="conversations-list">
      <template v-if="showConversationCards">
        <conversation-details-card
          v-for="result in conversations"
          :key="result.id"
          class="conversation-card-cmpt"
          :identifier="result.conversation.identifier"
          :assignee-name="result.conversation.agent_name"
          :contact-name="result.conversation.contact_name"
          :display-id="result.conversation.display_id"
          :content="result.content"
          :data="result"
          :search-term="savedSearchMeta.query"
          @routeNavigated="
            $emit('conversation-load', result.conversation.display_id)
          "
        />
        <div class="search-list-footer">
          <div
            v-if="uiFlags.isFetching && conversations.length"
            class="text-center"
          >
            <span class="spinner" />
          </div>
          <div v-if="showLoadMore" class="flex-row flex-justify">
            <woot-base-button
              variant="tertiary"
              size="small"
              @click="loadMoreResults"
            >
              {{ $t('CONVERSATION.SEARCH.RESULTS.LOAD_MORE') }}
            </woot-base-button>
          </div>
          <div
            v-if="showEndOfResultsText"
            class="text-center body-b2 text-light"
            v-text="$t('CONVERSATION.SEARCH.RESULTS.END_OF_RESULTS')"
          />
        </div>
      </template>
      <template v-if="showContactCards">
        <contact-details-card
          v-for="result in conversations"
          :key="result.id"
          class="conversation-card-cmpt"
          :data="result"
          :search-term="savedSearchMeta.query"
          @routeNavigated="
            conversationid => $emit('conversation-load', conversationid)
          "
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ConversationDetailsCard from 'dashboard/components/ui/ConversationDetailsCard';
import ContactDetailsCard from 'dashboard/components/ui/ContactDetailsCard';
import { SEARCH_BY } from 'dashboard/components/widgets/conversation/filter/SearchFilter/search/constants';
import { manifestSearchResults } from 'dashboard/components/widgets/conversation/filter/SearchFilter/search/helpers';

export default {
  components: { ConversationDetailsCard, ContactDetailsCard },
  props: {
    onShowSearchModal: {
      type: Function,
      default: () => {},
    },
    onClearSearch: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      endReached: false,
    };
  },
  computed: {
    ...mapGetters({
      savedSearchMeta: 'conversationSearch/getSavedSearchMeta',
      uiFlags: 'conversationSearch/getUIFlags',
    }),
    conversations() {
      return this.savedSearchMeta.results;
    },
    searchRequestPayload() {
      return this.savedSearchMeta.requestPayload;
    },
    showConversationCards() {
      if (!this.savedSearchMeta?.filters?.searchBy) return true;

      const {
        filters: {
          searchBy: { value },
        },
      } = this.savedSearchMeta;

      return !value || value === 'message';
    },
    showContactCards() {
      if (!this.savedSearchMeta.filters.searchBy) return false;

      const {
        filters: {
          searchBy: { value },
        },
      } = this.savedSearchMeta;
      return value === 'contact';
    },
    showEndOfResultsText() {
      return (
        this.conversations.length && this.endReached && !this.uiFlags.isFetching
      );
    },
    showLoadMore() {
      return (
        (this.showConversationCards || this.showContactCards) &&
        !this.endReached &&
        !this.uiFlags.isFetching
      );
    },
  },
  watch: {
    searchRequestPayload(newVal) {
      // reset endReached for new searches
      if (newVal.page === 1) {
        this.endReached = false;
      }
    },
  },
  methods: {
    loadMoreResults() {
      this.getSearchResults(true);
    },
    async getSearchResults(append = false) {
      const queryType = this.savedSearchMeta.type || SEARCH_BY.MESSAGE;
      const params = {
        ...this.searchRequestPayload,
        page: this.searchRequestPayload.page + 1,
        append: true,
      };
      this.$store.dispatch(
        'conversationSearch/saveSearchRequestPayload',
        params
      );
      if (queryType === SEARCH_BY.MESSAGE) {
        await this.performSearch('conversationSearch/get', params, append);
      } else {
        await this.performSearch('contacts/search', params, append);
      }
    },
    async performSearch(action, params, append = false) {
      const response = await manifestSearchResults(
        this.$store.dispatch,
        append,
        this.savedSearchMeta.query,
        () => this.$store.dispatch(action, params)
      );

      this.$store.dispatch(
        'conversationSearch/appendSearchConversationsRecord',
        response
      );
      if (!response.length) {
        this.endReached = true;
        if (!this.savedSearchMeta.query.trim()) this.hasSearched = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conversation-card-cmpt {
  border: none;
  border-bottom: 1px solid $neutral-grey-200;
  border-radius: $zero;
  margin-bottom: $zero;
}

.search {
  &-container {
    padding: $space-medium $space-slab $space-small;
    position: relative;
  }

  &-button {
    border: 1px solid $neutral-grey-400;
    color: $text-dark;
    justify-content: flex-start;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $height-h5;
    text-transform: capitalize;
  }
}

.close-icon {
  top: 29px;
  right: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0.8);
  width: $space-medium + $space-smaller;
}

.search-list-footer {
  margin-top: $space-normal;
  margin-bottom: $space-normal;
}
</style>
