<template>
  <div class="column content-box settings-box no-padding">
    <woot-base-button
      v-if="!modalMode"
      size="medium"
      front-icon="plus"
      class="settings-button"
      icon-view-box="0 0 20 20"
      @click="openAddPopup(null)"
    >
      {{ $t('LABEL_MGMT.HEADER_BTN_TXT') }}
    </woot-base-button>

    <div :class="modalMode ? null : 'settings-container'">
      <div :class="modalMode ? 'column' : 'column settings-main-menu-new'">
        <search-box
          v-if="!selectedTagsToPerformAction.length"
          class="search-box"
          :placeholder="$t('LABEL_MGMT.SEARCH_TXT')"
          :value="search"
          @setSearch="setSearch"
        />
        <div
          v-else-if="!modalMode"
          class="bulk-actions-container flex-row flex-align gap--normal"
        >
          <woot-base-button
            size="small"
            variant="tertiary"
            @click="handleSelectAll"
          >
            <label style="cursor: pointer" class="container-checkbox">
              <input
                v-model="selectAllChecked"
                type="checkbox"
                :checked="selectAllChecked"
                @click="handleSelectAll"
              />
              <span class="grey-checkmark checkmark" />
            </label>
            Select all
          </woot-base-button>
          <woot-base-button
            size="small"
            variant="secondary-danger"
            front-icon="delete"
            @click="bulkDeleteLabel"
          >
            Delete
          </woot-base-button>
          <woot-base-button
            size="small"
            variant="secondary"
            @click="bulkUpdateStatus(true)"
          >
            Activate
          </woot-base-button>
          <woot-base-button
            size="small"
            variant="secondary-danger"
            @click="bulkUpdateStatus(false)"
          >
            Deactivate
          </woot-base-button>
        </div>
        <woot-tabs
          v-if="!modalMode"
          :index="selectedTabIndex"
          @change="(index, { key }) => onApplyTypeFilter(index, key)"
        >
          <woot-tabs-item
            v-for="tab in tabs"
            :key="tab.key"
            :name="tab.name"
            :data="tab"
            :disabled="uiFlags.isFetching"
          />
        </woot-tabs>
        <div class="header">
          <span
            class="title-h4 text-dark"
            v-text="$t('LABEL_MGMT.LIST.TABLE_HEADER.TAGS')"
          />
        </div>
        <div
          v-if="!uiFlags.isFetching && records.length"
          class="nested-label-container custom-scroll bg-light-grey"
        >
          <nested-labels
            v-model="labels"
            :selected-tags="selectedTags"
            :on-open-add-popup="openAddPopup"
            :on-open-delete-popup="openDeletePopup"
            :modal-mode="modalMode"
            :search="search"
            :resource="selectedResourceType"
            :selected-tag="selectedTag"
            :selected-tags-to-perform-action="selectedTagsToPerformAction"
            :on-label-select="onLabelSelect"
            :on-tag-select="onTagSelect"
            :on-view-tag="onViewTag"
          />
          <div
            v-if="!uiFlags.isFetching && !labels.length && search"
            class="flex-row flex-justify flex-align zero-state"
          >
            <span class="title-h5 text-light" v-text="'No Data'" />
          </div>
        </div>
        <div
          v-if="uiFlags.isFetching"
          class="flex-column gap--small bg-light-grey"
        >
          <box-skeleton v-for="idx in 7" :key="idx" />
        </div>
        <table-zero-state
          v-if="!records.length"
          title="No Tags added till now"
          subtitle="Click on the Add Tag Button to add a new tag"
          asset-link="/brand-assets/inbox_zero_state.svg"
          class="table-zero-state"
        >
          <woot-base-button
            size="medium"
            front-icon="plus"
            style="margin-bottom: 5vh"
            icon-view-box="0 0 20 20"
            @click="openAddPopup(null)"
          >
            {{ $t('LABEL_MGMT.HEADER_BTN_TXT') }}
          </woot-base-button>
        </table-zero-state>
      </div>
      <settings-side-card v-if="!modalMode">
        <rhs-panel
          v-if="selectedTag.id"
          :key="selectedTag.id + selectedTag.parent_id || 1"
          :selected-tag="selectedTag"
          :resource="selectedResourceType"
          @updateLabel="handleUpdateLabel"
        />
        <educational-onboarding
          v-else
          class="full-height"
          :show-case-data="SHOWCASE_TAGS_DATA"
        />
        <div class="action-btns full-width flex-row flex-justify gap--one">
          <woot-base-button
            v-if="records.length"
            variant="secondary"
            full-width
            @click="showExportTagModal = true"
          >
            Export
          </woot-base-button>
          <woot-base-button
            variant="secondary"
            full-width
            @click="showImportTagModal = true"
          >
            Import
          </woot-base-button>
        </div>
      </settings-side-card>
    </div>

    <!-- import tag -->
    <import-tag
      v-if="showImportTagModal"
      @onClose="showImportTagModal = false"
    />

    <!-- export tag -->
    <export-tag
      v-if="showExportTagModal"
      @onClose="showExportTagModal = false"
    />

    <!-- add tag -->
    <woot-modal-new
      v-if="showAddPopup"
      :show="true"
      :on-close="hideAddPopup"
      :disable-btn="areLabelDetailsNotValid"
      @submit="addLabel"
    >
      <woot-modal-header-new :header-title="$t('LABEL_MGMT.ADD.TITLE')" />
      <template v-slot:paginated-components>
        <label-details
          key="labelDetails"
          class="label-settings-container"
          :resource="selectedResourceType"
          @labelDetails="onChangeLabelDetails"
          @formValidity="
            $event =>
              (areLabelDetailsNotValid = {
                ...areLabelDetailsNotValid,
                0: $event,
              })
          "
        />
        <label-heirarchy
          :key="selectedResourceType"
          class="heirarchy-settings-container"
          :parent-id="selectedParentId"
          :resource="selectedResourceType"
          @labelHeirarchy="labelHeirarchySetting = $event"
        />
      </template>
    </woot-modal-new>

    <!-- delete tag -->
    <woot-delete-modal-new
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      show-close
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LabelDetails from './settings/LabelDetails';
import LabelHeirarchy from './settings/LabelHeirarchy';
import NestedLabels from './NestedLabels';
import SearchBox from '../SearchBox';
import SettingsSideCard from '../SettingSideCard';
import RhsPanel from 'dashboard/components/ui/settings/tags/RhsPanel';
import ImportTag from './ImportTag';
import ExportTag from './ExportTag';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import downloadMixin from 'shared/mixins/downloadMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import labelsMixin from 'shared/mixins/labelsMixin';

import * as types from 'shared/constants/googleEventType';
import {
  SHOWCASE_TAGS_DATA,
  TYPES,
} from 'dashboard/routes/dashboard/settings/labels/constants';

export default {
  name: 'LabelHome',
  components: {
    NestedLabels,
    SearchBox,
    LabelDetails,
    LabelHeirarchy,
    SettingsSideCard,
    RhsPanel,
    ImportTag,
    ExportTag,
    EducationalOnboarding,
    TableZeroState,
    BoxSkeleton: {
      render(h) {
        return h('div', {
          attrs: { class: 'skeleton-animation skeleton-label' },
        });
      },
    },
  },
  mixins: [
    alertMixin,
    googleAnalyticsMixin,
    downloadMixin,
    mixPanelAnalyticsMixin,
    labelsMixin,
  ],
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: TYPES.conversation,
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    onLabelSelect: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      search: '',
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      showImportTagModal: false,
      showExportTagModal: false,
      selectAllChecked: false,
      areLabelDetailsNotValid: { 0: true },
      selectedTag: {},
      labelDetailsSetting: {},
      labelHeirarchySetting: {},
      selectedTagsToPerformAction: [],
      selectedParentId: null,
      selectedTabIndex: 0,
      selectedResourceType: this.resource,
      SHOWCASE_TAGS_DATA,
      tabs: [
        {
          key: TYPES.conversation,
          name: this.$t('LABEL_MGMT.LIST.TABS.CONVERSATION'),
        },
        {
          key: TYPES.contact,
          name: this.$t('LABEL_MGMT.LIST.TABS.CUSTOMER'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
    }),
    records() {
      return this.$store.getters['labels/getLabelsByResource'](
        this.selectedResourceType
      );
    },
    labels() {
      const memo = new Map();
      const searchLower = this.search ? this.search.toLowerCase().trim() : '';

      const includeAllDescendants = label => {
        if (memo.has(label)) {
          return memo.get(label);
        }
        const result = {
          ...label,
          labels: label.labels ? label.labels.map(includeAllDescendants) : [],
        };
        memo.set(label, result);
        return result;
      };

      const searchAndPreserveHierarchy = label => {
        if (memo.has(label)) {
          return memo.get(label);
        }

        const match = label.title.toLowerCase().includes(searchLower);
        if (match) {
          return includeAllDescendants(label);
        }

        let result = null;
        if (label.labels && label.labels.length > 0) {
          const childMatches = label.labels
            .map(searchAndPreserveHierarchy)
            .filter(Boolean);
          if (childMatches.length > 0) {
            result = { ...label, labels: childMatches };
          }
        }

        memo.set(label, result);
        return result;
      };

      let filteredLabels = this.modalMode
        ? this.filterClientLabels(this.filterActiveLabels(this.records))
        : this.filterClientLabels(this.records);

      if (searchLower) {
        filteredLabels = filteredLabels
          .map(searchAndPreserveHierarchy)
          .filter(Boolean);
      }

      return filteredLabels;
    },
    deleteMessage() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedTag.title
      } ?`;
    },
  },
  mounted() {
    this.applyTypeFilterHandler(this.resource);
  },
  methods: {
    onChangeLabelDetails(newDetails) {
      this.labelDetailsSetting = newDetails;
      const { resource_type } = newDetails;
      this.selectedResourceType = resource_type;
    },
    applyTypeFilterHandler(resource) {
      this.onApplyTypeFilter(resource === TYPES.contact ? 1 : 0, resource);
    },
    onApplyTypeFilter(index, key) {
      this.selectedTag = {};
      this.selectedTabIndex = index;
      this.selectedResourceType = key;
    },
    filterActiveLabels(labels) {
      return labels.reduce((acc, label) => {
        const filteredChildren = label.labels
          ? this.filterActiveLabels(label.labels)
          : [];

        // Include the label if it is active or if any of its children are active
        if (label.active || filteredChildren.length > 0) {
          const newLabel = { ...label, labels: filteredChildren };
          acc.push(newLabel);
        }

        return acc;
      }, []);
    },
    setSearch(value) {
      this.search = value;
    },
    onViewTag(label) {
      if (label.label_type === 'system') return;
      this.selectedTag = label;
    },
    toggleSelectAll() {
      this.selectAllChecked =
        this.selectedTagsToPerformAction.length === this.labels.length - 1;
    },
    handleSelectAll() {
      this.selectAllChecked = !this.selectAllChecked;

      if (this.selectAllChecked) {
        this.selectedTagsToPerformAction = [];
        this.labels.map(label => this.handleSelectAllTags(label));
      } else this.selectedTagsToPerformAction = [];
    },
    onTagSelect(label) {
      if (
        !this.selectedTagsToPerformAction.includes(label.id) &&
        (this.selectedTagsToPerformAction.includes(label.parent_id) ||
          !this.selectedTagsToPerformAction.includes(label.parent_id) ||
          label.parent_id === null)
      ) {
        this.selectedTagsToPerformAction.push(label.id);
        if (label.labels && label.labels.length) {
          label.labels.map(lab => {
            if (!this.selectedTagsToPerformAction.includes(lab.id))
              this.onTagSelect(lab);
            return null;
          });
        }
        return;
      }

      if (this.selectedTagsToPerformAction.includes(label.id)) {
        this.selectedTagsToPerformAction = this.selectedTagsToPerformAction.filter(
          label_id => label_id !== label.id
        );
        this.toggleSelectAll();
        return;
      }

      this.selectedTagsToPerformAction.push(label.id);
      this.toggleSelectAll();
    },
    openAddPopup(parentId) {
      this.googleAnalyticsEvent(
        types.default.LABEL_OPEN_ADD_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showAddPopup = true;
      this.selectedParentId = parentId;
      this.selectedTagsToPerformAction = [];
      if (parentId) {
        this.mix_panel_clicked_settings_add_sub_tag();
      } else {
        this.mix_panel_clicked_settings_add_tag();
      }
    },
    hideAddPopup() {
      this.googleAnalyticsEvent(
        types.default.LABEL_HIDE_ADD_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.googleAnalyticsEvent(
        types.default.LABEL_OPEN_DELETE_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showDeleteConfirmationPopup = true;
      this.selectedTag = response;
      this.selectedTagsToPerformAction = [];
    },
    closeDeletePopup() {
      this.googleAnalyticsEvent(
        types.default.LABEL_CLOSE_DELETE_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteLabel(this.selectedTag);
    },
    deleteLabel(labelObj) {
      this.googleAnalyticsEvent(
        types.default.LABEL_DELETE,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.$store
        .dispatch('labels/delete', {
          data: labelObj,
          resource_type: this.selectedResourceType,
        })
        .then(() => {
          this.onViewTag({});
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.mix_panel_deleted_settings_tag();
        })
        .catch(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.ERROR_MESSAGE'),
            'error'
          );
        });
    },
    handleSelectAllTags(label) {
      if (label.label_type === 'client') {
        this.selectedTagsToPerformAction.push(label.id);
      }
      if (label.labels && label.labels.length) {
        label.labels.map(id => {
          this.handleSelectAllTags(id);
          return {};
        });
      }
    },
    bulkUpdateStatus(isActive = false) {
      this.$store
        .dispatch('labels/bulkUpdateStatus', {
          labelIds: this.selectedTagsToPerformAction,
          isActive,
          resource_type: this.selectedResourceType,
        })
        .then(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
        })
        .finally(() => {
          this.onViewTag({});
          this.selectedTagsToPerformAction = [];
        });
    },
    bulkDeleteLabel() {
      this.$store
        .dispatch('labels/bulkDelete', this.selectedTagsToPerformAction)
        .then(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.ERROR_MESSAGE'),
            'error'
          );
        })
        .finally(() => {
          this.onViewTag({});
          this.selectedTagsToPerformAction = [];
          this.$store.dispatch('labels/get', this.selectedResourceType);
        });
    },
    addLabel() {
      const payload = {
        ...this.labelDetailsSetting,
        ...this.labelHeirarchySetting,
      };
      const { resource_type } = payload;

      this.googleAnalyticsEvent(
        types.default.ADD_LABEL_FROM_SETTINGS,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );

      this.$store
        .dispatch('labels/create', payload)
        .then(response => {
          this.showAlert(
            this.$t('LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.labelDetailsSetting = {};
          this.labelHeirarchySetting = {};
          this.hideAddPopup();

          this.applyTypeFilterHandler(resource_type);

          this.onViewTag(response.data);
        })
        .catch(error => {
          this.showAlert(
            error.data?.message || this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE'),
            'error'
          );
        })
        .finally(() => {
          this.scrollSelectedTagIntoView();
        });
    },
    updateLabelsStateWhenResourceTypeShift(
      updatedLabel,
      oldResource,
      newResource
    ) {
      this.$store.dispatch('labels/remove', {
        data: updatedLabel,
        resource_type: oldResource,
      });
      this.$store.dispatch('labels/add', {
        data: updatedLabel,
        resource_type: newResource,
      });
    },
    handleUpdateLabel(payload) {
      const { resource_type } = payload;
      this.$store
        .dispatch('labels/update', payload)
        .then(response => {
          this.showAlert(
            this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );

          if (resource_type !== this.selectedResourceType) {
            this.updateLabelsStateWhenResourceTypeShift(
              response.data,
              this.selectedResourceType,
              resource_type
            );
            this.applyTypeFilterHandler(resource_type);
          }

          this.selectedTag = response.data;
        })
        .catch(() => {
          this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
        })
        .finally(() => {
          this.scrollSelectedTagIntoView();
        });
    },
    scrollSelectedTagIntoView() {
      setTimeout(() => {
        if (document.querySelector('.is-selected')) {
          document
            .querySelector('.is-selected')
            .scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 500);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.no-padding {
  padding-left: 0.1rem;
}

.bulk-actions-container {
  .container-checkbox {
    margin: $zero $space-two 1.8rem $zero;
    padding-left: $zero;
  }

  .select-all-btn {
    color: $text-dark;
  }

  .delete-btn {
    height: 4.4rem;
    width: $space-slab * 10;
  }
}

.header {
  background-color: $info-blue-20;
  border-radius: $border-radius $border-radius $zero $zero;
  margin-top: $space-slab;
  padding: $space-normal $space-medium;
}

.nested-label-container {
  padding: $space-normal;
  overflow: overlay;

  .zero-state {
    background-color: $neutral-white;
    padding: $space-normal;
  }
}

.table-zero-state,
.nested-label-container {
  height: calc(100% - 16.7rem) !important;
}

.skeleton-label {
  border-radius: $border-radius-smaller !important;
  height: $space-medium * 3;
  padding: $space-slab $space-normal;
}

.bg-light-grey {
  background-color: $neutral-grey-200;
  padding: $space-small;
}

.action-btns {
  border-top: 2px solid $neutral-grey-200;
  padding: $space-two;
}

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      max-width: $space-large * 10;
    }

    .settings-side-card {
      padding: $zero;
    }
  }
}
</style>
