/* eslint-disable no-unused-vars */
import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import InboxesAPI from '../../api/inboxes';
import WebChannel from '../../api/channel/webChannel';
import FBChannel from '../../api/channel/fbChannel';
import TwilioChannel from '../../api/channel/twilioChannel';
import ChatApiChannel from '../../api/channel/chatApiChannel';
import MaytapiChannel from '../../api/channel/maytapiChannel';
import ZokoChannel from '../../api/channel/zokoChannel';
import GupshupChannel from '../../api/channel/gupshupChannel';
import GupshupEnterpriseChannel from '../../api/channel/gupshupEnterpriseChannel';
import AclChannel from '../../api/channel/aclChannel';
import FreshchatChannel from '../../api/channel/freshchatChannel';
import TwoFactorChannel from '../../api/channel/twoFactorChannel';
import WatiChannel from '../../api/channel/watiChannel';
import ValueFirstChannel from '../../api/channel/valueFirstChannel';
import WhatsappApiChannel from '../../api/channel/whatsappApiChannel';
import KnowlarityChannelApi from '../../api/channel/knowlarityChannel';
import ThreeSixtyDialog from '../../api/channel/threeSixtyDialog';
import IcsChannel from '../../api/channel/icsChannel';
import InfobipChannel from '../../api/channel/infobipChannel';
import { isAWhatsAppChannel, INBOX_TYPES } from 'shared/mixins/inboxMixin';

const buildInboxData = inboxParams => {
  const formData = new FormData();
  const {
    channel = {},
    non_widget_channel = {},
    ...inboxProperties
  } = inboxParams;
  Object.keys(inboxProperties).forEach(key => {
    formData.append(key, inboxProperties[key]);
  });

  const { selectedFeatureFlags = [], ...channelParams } = channel;

  if (selectedFeatureFlags.length)
    selectedFeatureFlags.forEach(featureFlag => {
      formData.append(`channel[selected_feature_flags][]`, featureFlag);
    });
  else formData.append('channel[selected_feature_flags][]', '');

  Object.keys(non_widget_channel).forEach(key => {
    formData.append(`non_widget_channel[${key}]`, non_widget_channel[key]);
  });
  Object.keys(channelParams).forEach(key => {
    formData.append(`channel[${key}]`, channel[key]);
  });
  return formData;
};

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isUpdatingAutoAssignment: false,
    isDeleting: false,
  },
};

export const getters = {
  getInboxes($state) {
    return $state.records;
  },
  getKnowlarityInboxes($state) {
    return $state.records.filter(
      inbox => inbox.channel_type === INBOX_TYPES.KNOWLARITY
    );
  },
  getAutoAssignmentInboxes($state) {
    return $state.records.filter(inbox => inbox.enable_auto_assignment);
  },
  getManualAssignmentInboxes($state) {
    return $state.records.filter(inbox => !inbox.enable_auto_assignment);
  },
  getWhatsAppInboxes($state) {
    return $state.records.filter(inbox =>
      isAWhatsAppChannel(inbox.channel_type)
    );
  },
  getThreeSixtyInboxes($state) {
    return $state.records.filter(
      inbox => inbox.channel_type === INBOX_TYPES.THREE_SIXTY_DIALOG
    );
  },
  // when a channel is not active it can be considered as in-progress channel
  getInProgressThreeSixtyInboxes: $state => {
    return $state.records
      .filter(inbox => inbox.channel_type === INBOX_TYPES.THREE_SIXTY_DIALOG)
      .sort((a, b) => {
        if (a.created_at < b.created_at) return 1;
        if (a.created_at > b.created_at) return -1;
        return 0;
      })
      .filter(
        inbox => !inbox.three_sixty_channel_id || !inbox.three_sixty_client_id
      );
  },
  getEmailInboxes($state) {
    return $state.records.filter(
      inbox => inbox.channel_type === INBOX_TYPES.EMAIL
    );
  },
  getInbox: $state => inboxId => {
    const [inbox] = $state.records.filter(
      record => record.id === Number(inboxId)
    );
    return inbox || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getUmsEnabledForAnyOneInbox($state) {
    return $state.records.some(inbox => inbox.ums_enabled === true);
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxesAPI.get();
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_INBOXES, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
    }
  },
  createChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createWebsiteChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(buildInboxData(params));
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createKnowlarityChannel: async ({ commit }, data) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await KnowlarityChannelApi.create(data);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  createTwilioChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await TwilioChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createChatApiChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await ChatApiChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createMaytapiChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await MaytapiChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createAclChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await AclChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createValueFirstChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await ValueFirstChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createGupshupEnterpriseChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await GupshupEnterpriseChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createIcsChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await IcsChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createInfobipChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await InfobipChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createZokoChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await ZokoChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createGupshupChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await GupshupChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createFreshchatChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FreshchatChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createTwoFactorChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await TwoFactorChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createWhatsappAPIChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WhatsappApiChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createWatiChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WatiChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createFBChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createThreeSixtyDialogChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await ThreeSixtyDialog.create(params);
      const {
        channel: { id: channel_id, ...channelRest },
        ...rest
      } = response.data;
      commit(types.default.ADD_INBOXES, {
        channel_id,
        ...channelRest,
        ...rest,
      });
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  getTwoFactorBalanceTrans: async ({ commit }, data) => {
    try {
      const response = await TwoFactorChannel.balanceTrans(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getTwoFactorReportTrans: async ({ commit }, data) => {
    try {
      const response = await TwoFactorChannel.reportTrans(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateInbox: async ({ commit }, { id, formData = true, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingAutoAssignment: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
      throw new Error(error);
    }
  },
  bulkUpdateAutoAssignment: async (_, data) => {
    try {
      await InboxesAPI.bulkUpdateAutoAssignment(data);
    } catch (error) {
      throw new Error(error);
    }
  },
  updateInboxWithoutOverriding: async ({ commit }, data) => {
    commit(types.default.EDIT_INBOXES_WITHOUT_OVERRIDING, data);
  },
  updateKnowlarityInbox: async ({ commit }, data) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingAutoAssignment: true,
    });
    try {
      const response = await KnowlarityChannelApi.updateKnowlarityInbox(data);
      commit(types.default.EDIT_INBOXES, {
        sr_number: data.srNumber,
        caller_id: data.callerId,
      });
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
      throw error;
    }
  },
  delete: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await InboxesAPI.delete(inboxId);
      commit(types.default.DELETE_INBOXES, inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  reauthorizeFacebookPage: async ({ commit }, params) => {
    try {
      const response = await FBChannel.reauthorizeFacebookPage(params);
      commit(types.default.EDIT_INBOXES, response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  inboxStatusChanged: async ({ commit }, data) => {
    commit(types.default.INBOXSTATUS_CHANGED, data);
  },
  rebootChatApi: async ({ commit }, data) => {
    const response = await ChatApiChannel.reboot(data);
    return response;
  },
  rebootedAtChatApi: async ({ commit }, data) => {
    const response = await ChatApiChannel.rebootedAt(data);
    return response;
  },
  rebootMaytapi: async ({ commit }, data) => {
    const response = await MaytapiChannel.reboot(data);
    return response;
  },
  rebootedAtMaytapi: async ({ commit }, data) => {
    const response = await MaytapiChannel.rebootedAt(data);
    return response;
  },
  reauthorizeThreeSixty: async (_, phone) => {
    try {
      await ThreeSixtyDialog.reauthorize(phone);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_INBOXES_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_INBOXES]: MutationHelpers.set,
  [types.default.SET_INBOXES_ITEM]: MutationHelpers.setSingleRecord,
  [types.default.ADD_INBOXES]: MutationHelpers.create,
  [types.default.EDIT_INBOXES]: MutationHelpers.update,
  [types.default.EDIT_INBOXES_WITHOUT_OVERRIDING]:
    MutationHelpers.updateAttributes,
  [types.default.DELETE_INBOXES]: MutationHelpers.destroy,
  [types.default.INBOXSTATUS_CHANGED](_state, data) {
    let id = data.id;
    let status = data.status;
    _state.records.forEach((element, index) => {
      if (element.id === id) {
        _state.records[index].status = status;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
