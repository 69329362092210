<template>
  <div class="conversations-sidebar columns">
    <div class="conversations-sidebar-header">
      <chat-list-top-deprecated
        @filterChange="updateFilter"
        @statusFilterChange="updateStatusType"
      />

      <woot-tabs
        v-if="assigneeTabItems.length !== 0"
        :index="selectedTabIndex"
        class="assignee-tabs"
        @change="
          (_, { key }) => updateAssigneeTab(key, fetchConversationsIfNeeded)
        "
      >
        <woot-tabs-item
          v-for="tab in assigneeTabItems"
          :key="tab.key"
          :name="getTabName(tab)"
          :data="tab"
          class="assignee-tab"
        />
      </woot-tabs>

      <div class="conversation-sort-filter">
        <conversation-sort-filter
          ref="conversationSortFilter"
          variant="tertiary"
          @applyFilter="applySortFilter"
        />
      </div>

      <!-- <reference-box
        v-if="isConcurrencyEnabled"
        class="reference-box flex-row flex-justify flex-gap--small"
        text-color="#F08700"
        icon-name="ticket"
        icon-color="darkyellow"
        icon-size="semimedium"
        :content="agentTicketInfo"
      >
        <div
          v-if="concurrencyLimitReached"
          v-tooltip="agentInfoTooltipContent"
          style="margin-left: 8px"
        >
          <icons name="info" size="normal" color="darkyellow" />
        </div>
      </reference-box> -->

      <div v-if="selected.length" class="selection-bar">
        <div class="checkbox-container">
          <label class="container-checkbox">
            <input
              type="checkbox"
              :checked="selectAllLoaded"
              value="select_all"
              @input="selectCard(selectAllLoaded)"
            />
            <span class="checkmark"></span>
          </label>
          <label
            v-if="!selectAllLoaded"
            class="checkbox-label"
            for="select_all"
            style="margin-top: 0.6rem"
          >
            {{ `${$t('CONVERSATION.SELECT_BUTTON')} (${selected.length})` }}
          </label>
          <div v-else class="checkbox-container">
            <label
              class="checkbox-label"
              for="select_all"
              style="margin-top: 0.8rem"
            >
              {{ `(${selected.length})` }}
            </label>
            <div class="checkbox-container" style="margin-left: 1.6rem">
              <label class="container-checkbox">
                <input
                  type="checkbox"
                  :checked="selectAll"
                  value="select_all"
                  @input="selectAllConversation"
                />
                <span class="checkmark"></span>
              </label>
              <label
                class="checkbox-label"
                for="select_all"
                style="margin-top: 0.6rem"
              >
                {{ `${$t('CONVERSATION.SELECT_BUTTON')} (${selectCount})` }}
              </label>
            </div>
          </div>
        </div>
        <woot-primary-button
          type="button"
          size="small"
          :name="$t('CONVERSATION.MODIFY_BUTTON')"
          front-icon="refresh"
          icon-size="semimedium"
          @click="showModifyPopup"
        />
      </div>
    </div>

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>
    <div class="conversations-list">
      <conversation-card
        v-for="chat in conversationShowList"
        :key="chat.id"
        :show-select-option="true"
        :selected="isSelected(chat.id)"
        :selection="!!selected.length"
        :select-all="selectAllLoaded"
        :active-tab="activeAssigneeTab"
        :active-status="activeStatus"
        :chat="chat"
        :team-id="teamId"
        :chat-account-name="companyName(chat.account_id)"
        :refresh-sla-status="slaStatusRerenderMap[chat.id] || 0"
        @select="selectCard"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner" />
      </div>

      <div
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        class="clear button load-more-conversations"
        @click="fetchConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </div>

      <p
        v-if="
          conversationList.length &&
            hasCurrentPageEndReached &&
            !chatListLoading
        "
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>

    <bulk-action-modal
      v-if="showModifyModal"
      :show.sync="showModifyModal"
      :selected-conv="selected"
      :selected-count="selectAll ? selectCount : selected.length"
      :select-all="selectAll"
      :filters="appliedConversationFilters"
      :on-close="hideModifyPopup"
      @done="selectCard(true)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { endOfDay, subDays, startOfDay } from 'date-fns';

import ConversationSortFilter from 'dashboard/components/widgets/conversation/filter/ConversationSortFilter';
import ConversationCard from 'dashboard/components/widgets/conversation/ConversationCard';
import BulkActionModal from 'dashboard/components/widgets/conversation/BulkActionModal';
// import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import ChatListTopDeprecated from './ChatListTopDeprecated';

import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import conversationMixin from 'dashboard/mixins/conversations';
import isAdmin from 'dashboard/mixins/isAdmin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { BUS_EVENTS } from 'shared/constants/busEvents';
import wootConstants from 'dashboard/constants';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    ConversationCard,
    ChatListTopDeprecated,
    ConversationSortFilter,
    BulkActionModal,
    // ReferenceBox,
  },
  mixins: [
    alertMixin,
    timeMixin,
    conversationMixin,
    isAdmin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      selectAllLoaded: false,
      selectAll: false,
      showModifyModal: false,
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      count: 0,
      prevMineCount: 0,
      selected: [],
      slaStatusRerenderMap: {},
      teamId: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      appliedConversationFilters: 'getConversationFilters',
      currentRole: 'getCurrentRole',
      currentAgentTicketCount: 'agents/getCurrentAgentTicketsCount',
    }),
    isConcurrencyEnabled() {
      return this.currentAgentTicketsMeta.concurrencyEnabled;
    },
    currentAgentDetails() {
      return this.$store.getters['agents/getAgent'](this.currentUserID);
    },
    currentAgentTicketsMeta() {
      return {
        concurrencyEnabled: this.currentAgentDetails.concurrency_enabled,
        concurrencyLimit: this.currentAgentDetails.concurrency_limit,
        assignedCount: this.currentAgentTicketCount,
      };
    },
    concurrencyLimitReached() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;
      return assignedCount >= concurrencyLimit;
    },
    agentTicketInfo() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;

      return this.concurrencyLimitReached
        ? 'Concurrency Limit Reached.'
        : `Concurrency status ${assignedCount}/${concurrencyLimit} Tickets`;
    },
    agentInfoTooltipContent() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;

      return assignedCount >= concurrencyLimit
        ? 'You have reached the maximum number of tickets that can be assigned to you. Please resolve your existing tickets before new ones can be assigned.'
        : 'You can work on multiple tickets at the same time. The number of tickets you can work on simultaneously is limited by your account administrator by setting concurrency limit.';
    },
    rerenderConversationFilters() {
      return this.appliedConversationFilters ? Math.random() : '';
    },
    conversationInboxId() {
      return this.$route.params.inbox_id;
    },
    assigneeTabItems() {
      const statusType = this.activeStatus;
      const sectionsToRemove =
        wootConstants.CONDITIONS_TO_REMOVE_ASSIGNEE_TAB[statusType] || [];

      return this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').reduce((acc, item) => {
        this.setDocumentTitle(item);

        if (!this.isAdmin) {
          const {
            uiFlags: { isAllTabHidden, isQueuedTabHidden },
          } = this;

          const isAllTab = item.KEY === wootConstants.ASSIGNEE_TYPE.ALL;

          if (isAllTabHidden && isAllTab) return acc;

          const isQueuedTab =
            item.KEY === wootConstants.ASSIGNEE_TYPE.UNASSIGNED;

          if (isQueuedTabHidden && isQueuedTab) return acc;

          if (isQueuedTabHidden && isAllTabHidden) return acc;
        }

        if (!sectionsToRemove.includes(item.KEY)) {
          acc.push({
            key: item.KEY,
            name: item.NAME,
            label: `(${this.count > 9000 ? '9000+' : this.count})`,
          });
        }

        return acc;
      }, []);
    },
    selectedTabIndex() {
      return this.assigneeTabItems.findIndex(
        tabItem => tabItem.key === this.activeAssigneeTab
      );
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPage'](
        this.activeAssigneeTab
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.activeAssigneeTab
      );
    },
    conversationList() {
      let conversationList = [];
      if (this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ME)
        conversationList = this.mineChatsList.slice();
      else if (
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.UNASSIGNED
      )
        conversationList = this.unAssignedChatsList.slice();
      else conversationList = this.allChatList.slice();

      conversationList = this.sortConversationsByLastMessageDate(
        conversationList
      );

      conversationList = this.getFilteredConversations(
        conversationList,
        this.appliedConversationFilters
      );

      return conversationList;
    },
    conversationShowList() {
      return this.conversationList;
    },
    defaultTabBasedOnRole() {
      return this.currentRole === 'agent'
        ? wootConstants.ASSIGNEE_TYPE.ME
        : wootConstants.ASSIGNEE_TYPE.ALL;
    },
    selectCount() {
      let selectedIndex = this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').findIndex(
        item => item.KEY === this.activeAssigneeTab
      );

      return this.conversationStats[
        this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS')[selectedIndex].COUNT_KEY
      ];
    },
  },
  watch: {
    appliedConversationFilters(n) {
      if (n.canSetDefaultValues) {
        this.setDefaultStatusTimeFilter();
        this.setConversationSortDefaultValues([]);
        this.activeStatus = wootConstants.STATUS_TYPE.OPEN;

        this.updateAssigneeTab(
          wootConstants.ASSIGNEE_TYPE.ME,
          this.fetchConversationsIfNeeded
        );

        this.$store.dispatch('setChatStatus', this.activeStatus);
      } else this.setFilterStates(n);
    },
    deep: true,
  },
  mounted() {
    this.updateAssigneeTab(this.defaultTabBasedOnRole);

    this.$store.dispatch('setChatStatus', this.activeStatus);

    this.updateConversationFilter('inboxId', this.conversationInboxId);

    bus.$on('fetch_conversation_stats', this.updateConversationStats);

    bus.$on(BUS_EVENTS.SLA_BREACHED, this.handleSlaBreach);

    // this.fetchCurrentAgentTicketCount();
  },
  beforeDestroy() {
    bus.$off(BUS_EVENTS.SLA_BREACHED, this.handleSlaBreach);
  },
  methods: {
    handleSlaBreach({ chatId }) {
      // Increment the rerenderSlaStatus counter for the specific chat
      if (!this.slaStatusRerenderMap[chatId]) {
        this.$set(this.slaStatusRerenderMap, chatId, 1);
      }
      this.slaStatusRerenderMap[chatId] += 1;
    },
    fetchCurrentAgentTicketCount() {
      this.$store.dispatch(
        'agents/getCurrentAgentTicketCount',
        this.currentUserID
      );
    },
    getTabName(tab) {
      const isActiveTab = this.activeAssigneeTab === tab.key;
      const tabLabel = isActiveTab ? ` ${tab.label}` : '';
      return `${tab.name}${tabLabel}`;
    },
    setFilterStates(filters) {
      const { assigneeType, status, isNew: newest, oldest } = filters;
      // set assignee
      this.updateAssigneeTab(assigneeType, this.fetchConversationsIfNeeded);

      // set status
      this.activeStatus = status;
      this.$store.dispatch('setChatStatus', status);

      // set Conversation Sort filter
      const sortByBools = {
        newest,
        oldest,
      };

      // Set selected values of sort filter as the default.
      this.setConversationSortDefaultValues(
        Object.keys(sortByBools).filter(key => sortByBools[key])
      );

      this.resetAndFetchData();
    },
    sortConversationsByLastMessageDate(conversationList) {
      return conversationList.sort((a, b) => {
        const lastMessageA = a.messages?.last();
        const lastMessageB = b.messages?.last();

        const dateA = lastMessageA ? lastMessageA.created_at : 0;
        const dateB = lastMessageB ? lastMessageB.created_at : 0;

        return dateB - dateA;
      });
    },
    updateConversationFilter(key, value) {
      this.$store.dispatch('updateConversationFilters', {
        key,
        value,
      });
    },
    setDocumentTitle(item) {
      this.count = this.conversationStats[item.COUNT_KEY] || 0;
      if (item.KEY === wootConstants.ASSIGNEE_TYPE.ME) {
        // update document title with updated mine count
        document.title = `(${this.count}) ${this.companyName(
          this.accountId
        )} | LimeChat`;

        this.prevMineCount = this.count;
      }
    },
    companyName(currentAccountId) {
      let currentAccount = null;
      let companyName = null;

      if (this.currentUser.accounts && this.currentUser.accounts.length !== 0) {
        currentAccount = this.currentUser.accounts.filter(
          acc => acc.id === currentAccountId
        );
        companyName = currentAccount[0].name;
      }

      return companyName;
    },
    updateConversationStats(data) {
      this.$store.dispatch('conversationStats/update', {
        ...data,
        activeStatus: this.activeStatus,
        userId: this.currentUserID,
      });
    },
    isSelected(id) {
      return this.selected.includes(id);
    },
    selectCard(selected, id = -1) {
      if (id > 0) {
        if (selected) {
          this.selected.splice(this.selected.indexOf(id), 1);
          this.selectAllLoaded = false;
          this.selectAll = false;
        } else this.selected.push(id);
      } else if (selected) {
        this.selectAllLoaded = false;
        this.selectAll = false;
        this.selected = [];
      } else {
        this.mix_panel_clicked_ticket_select();
        this.selectAllLoaded = true;
        this.selected = this.conversationList.map(item => item.id);
      }
    },
    resetAndFetchData() {
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchConversations();
    },
    fetchConversations() {
      this.googleAnalyticsEvent(
        types.default.LOAD_MORE_CONVERSATIONS,
        types.default.DASHBOARD_MID_PANEL,
        types.default.LOAD_MORE_CONVERSATIONS
      );

      this.updateConversationFilter('page', this.currentPage + 1);

      this.$store
        .dispatch('fetchAllConversations', this.appliedConversationFilters)
        .then(newChats => {
          this.onConversationLoad();
          this.$store.dispatch(
            'conversationPage/setCurrentPage',
            {
              filter: this.activeAssigneeTab,
              page: this.currentPage + 1,
            },
            { root: true }
          );

          if (!newChats.length) {
            this.$store.dispatch(
              'conversationPage/setEndReached',
              { filter: this.activeAssigneeTab },
              { root: true }
            );
          }
        });
    },
    onConversationLoad() {
      this.$emit('conversation-load');
    },
    updateAssigneeTab(selectedTab, callback) {
      this.selected = [];
      this.selectAllLoaded = false;
      if (this.activeAssigneeTab !== selectedTab) {
        this.googleAnalyticsEvent(
          types.default.CONVERSATION_TAB_SWITCH,
          types.default.DASHBOARD_MID_PANEL,
          selectedTab
        );
        window.history.pushState(
          {},
          null,
          `${this.$route.path}?assignee_tab=${selectedTab}`
        );

        this.activeAssigneeTab = selectedTab;
        this.updateConversationFilter('assigneeType', this.activeAssigneeTab);

        if (callback) callback();
      }
    },
    fetchConversationsIfNeeded() {
      if (!this.currentPage) this.fetchConversations();
    },
    updateStatusType(value) {
      this.selected = [];
      this.selectAllLoaded = false;
      if (this.activeStatus !== value) {
        this.activeStatus = value;
        this.setActiveAssigneeTab();
        this.$store.dispatch('setChatStatus', value);
        this.updateConversationFilter('status', value);
        this.resetAndFetchData();
      }
    },
    setActiveAssigneeTab() {
      if (
        !this.assigneeTabItems.find(
          tabItem => tabItem.key === this.activeAssigneeTab
        )
      ) {
        this.updateAssigneeTab(
          this.defaultTabBasedOnRole,
          this.fetchConversationsIfNeeded
        );
      }
    },
    setConversationSortDefaultValues(values) {
      this.$refs.conversationSortFilter.selectedFilterName = values;
    },
    setDefaultStatusTimeFilter() {
      const to = endOfDay(new Date());
      const from = startOfDay(subDays(new Date(), 6));
      this.updateConversationFilter('statusTimeRange', { from, to });
      this.resetAndFetchData();
    },
    updateFilter(key, value) {
      this.updateConversationFilter('page', 1);
      switch (key) {
        case 'label':
          this.updateConversationFilter('labels', value);
          break;

        case 'change_inbox':
          if (value.id === 0)
            this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
          else
            this.$router.push(
              `/app/accounts/${this.accountId}/inbox/${value.id}`
            );
          this.updateConversationFilter('inboxId', value.id || undefined);
          this.mix_panel_clicked_inbox_filter_menu_item(
            'Tickets',
            value.id === 0 ? 'All' : value.type
          );
          break;

        case 'status_time':
          this.updateConversationFilter('statusTimeRange', value);

          this.updateAssigneeTab(this.defaultTabBasedOnRole);

          if (value && !value.isFiredOnMount) {
            this.mix_panel_clicked_date_filter_menu_item(
              'Tickets',
              value.date_range_name
            );
          }
          break;

        default:
          this.showAlert(
            this.$t('CONVERSATION.FILTER.APPLICATION.ERROR'),
            'error'
          );
          break;
      }

      this.setFilterStates(this.appliedConversationFilters);
    },
    applySortFilter(_, state) {
      this.setConversationSortFilter(state);
      this.resetAndFetchData();
    },
    selectAllConversation() {
      this.mix_panel_clicked_ticket_select_all();
      this.selectAll = !this.selectAll;
    },
    showModifyPopup() {
      this.mix_panel_clicked_ticket_bulk_modify();
      this.showModifyModal = true;
    },
    hideModifyPopup() {
      this.showModifyModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.assignee-tabs {
  padding: $zero $space-slab;
  flex-wrap: nowrap;

  .assignee-tab {
    min-width: fit-content;
    width: 100%;
  }
}

.conversation-sort-filter {
  margin: $space-six $space-slab;
}

.reference-box {
  max-width: 100%;
}

.spinner {
  margin-top: $space-normal;
  margin-bottom: $space-normal;
}
</style>
