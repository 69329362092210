import { frontendURL } from '../helper/URLHelper';

export const getSidebarItems = accountId => ({
  common: {
    routes: [
      'home',
      'create_ticket',
      'create_ticket_with_inbox',
      'inbox_dashboard',
      'inbox_conversation',
      'overview_panel',
      'orders_panel',
      'create_order_cart',
      'products_panel',
      'conversation_through_inbox',
      'conversations_through_team',
      'products_panel_through_inbox',
      'overview_panel_through_inbox',
      'orders_panel_through_inbox',
      'create_order_cart_through_inbox',
      'contacts_dashboard',
      'notifications_dashboard',
      'account_analytics',
      'individual_agent_analytics',
      'analytics_conversation',
      'automation_list',
      'create_automation',
      'create_automation_with_template_id',
      'edit_automation',
      'csat_analytics',
      'billing_deactivated',
      'profile_settings',
      'profile_settings_index',
      'conversation_overview',
      'agent_overview',
      'bot_performance',
      'sales_analytics',
      'shopify_analytics',
      'label_conversations',
      'conversations_through_label',
      'products_panel_through_label',
      'overview_panel_through_label',
      'orders_panel_through_label',
      'create_order_cart_through_label',
      'team_conversations',
      'notifications_index',
      'agent_list',
      'teams_list',
      'canned_list',
      'labels_list',
      'broadcast',
      'create_broadcast',
      'broadcast_reports',
      'broadcast_cohorts',
      'broadcast_opt_out',
      'settings_inbox',
      'settings_inbox_new',
      'settings_inbox_list',
      'settings_inbox_show',
      'settings_inboxes_page_channel',
      'settings_inboxes_add_agents',
      'settings_inbox_finish',
      'settings_billing_plan',
      'settings_choose_billing_plan',
      'settings_integrations',
      'settings_shopify_integration',
      'settings_integrations_kapture',
      'settings_integrations_zohodesk',
      'settings_integrations_freshdesk',
      'settings_integrations_zendesk',
      'settings_integrations_onedirect',
      'settings_integrations_odoo',
      'settings_integrations_webhook',
      'settings_integrations_shopify',
      'settings_integrations_integration',
      'settings_integrations_shiprocket',
      'settings_integrations_ezyslips',
      'settings_integrations_pickrr',
      'settings_integrations_easyecom',
      'settings_integrations_clickpost',
      'settings_integrations_shipdelight',
      'settings_integrations_delhivery',
      'settings_integrations_damensch',
      'settings_integrations_bombayshirts',
      'settings_integrations_unicommerce',
      'settings_integrations_bluedart',
      'settings_product',
      'settings_product_list',
      'settings_product_show',
      'general_settings',
      'bot_csat_config_settings',
      'ticket_assignment',
      'general_settings_index',
      'settings_template',
      'settings_bot_management_index',
      'settings_store_notifications',
      'settings_teams_list',
      'settings_teams_new',
      'settings_teams_add_agents',
      'settings_teams_finish',
      'settings_teams_edit',
      'settings_teams_edit_members',
      'settings_teams_edit_finish',
      'settings_custom_ticket_fields',
      'settings_create_custom_field',
      'settings_edit_custom_field',
      'settings_sla',
      'settings_create_sla',
      'settings_edit_sla',
    ],
    menuItems: {
      assignedToMe: {
        icon: 'chatRound',
        activeIcon: 'chatRoundFilled',
        label: 'CONVERSATIONS',
        hasSubMenu: false,
        key: '',
        toState: frontendURL(`accounts/${accountId}/dashboard`),
        toolTip: 'Conversation from all subscribed inboxes',
        toStateName: 'home',
      },
      individualAgentAnalytics: {
        icon: 'graph',
        activeIcon: 'graphFilled',
        label: 'ANALYTICS',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/analytics/me`),
        toStateName: 'individual_agent_analytics',
      },
      analytics: {
        icon: 'graph',
        activeIcon: 'graphFilled',
        label: 'ANALYTICS',
        hasSubMenu: true,
        toState: frontendURL(`accounts/${accountId}/analytics/`),
        toStateName: 'conversation_overview',
        children: [
          {
            id: 'overview',
            label: 'CONVERSATION_OVERVIEW',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/analytics/conversation_overview`
            ),
            toStateName: 'conversation_overview',
          },
          {
            id: 'agent_overview',
            label: 'AGENT_OVERVIEW',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/analytics/agent_overview`
            ),
            toStateName: 'agent_overview',
          },
          {
            id: 'bot_performance',
            label: 'BOT_PERFORMANCE',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/analytics/bot_performance`
            ),
            toStateName: 'bot_performance',
          },
          {
            id: 'csat',
            label: 'CSAT',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/analytics/csat`),
            toStateName: 'csat_analytics',
          },
          {
            id: 'sales_analytics',
            label: 'SALES_ANALYTICS',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/analytics/sales_analytics`
            ),
            toStateName: 'sales_analytics',
          },
          {
            id: 'shopify_analytics',
            label: 'SHOPIFY_ANALYTICS',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/analytics/shopify_analytics`
            ),
            toStateName: 'shopify_analytics',
          },
        ],
      },
      broadcast: {
        icon: 'megaphone',
        activeIcon: 'megaphoneFilled',
        label: 'BROADCAST',
        hasSubMenu: true,
        toState: frontendURL(`accounts/${accountId}/broadcast`),
        toStateName: 'broadcast_reports',
        children: [
          {
            id: 'broadcast_reports',
            label: 'BROADCAST_REPORT',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/broadcast/reports`),
            toStateName: 'broadcast_reports',
          },
          {
            id: 'broadcast_opt_out',
            label: 'BROADCAST_OPT_OUT',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/broadcast/opt_out`),
            toStateName: 'broadcast_opt_out',
          },
          {
            id: 'broadcast_cohorts',
            label: 'BROADCAST_COHORT',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/broadcast/cohorts`),
            toStateName: 'broadcast_cohorts',
          },
        ],
      },
      contacts: {
        icon: 'people',
        activeIcon: 'peopleFilled',
        label: 'CONTACTS',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/contacts`),
        toStateName: 'contacts_dashboard',
      },
      notifications: {
        icon: 'bell',
        label: 'NOTIFICATIONS',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/notifications`),
        toStateName: 'notifications_dashboard',
      },
      settings: {
        icon: 'settings',
        activeIcon: 'settingsFilled',
        label: 'SETTINGS',
        hasSubMenu: true,
        toState: frontendURL(`accounts/${accountId}/settings`),
        toStateName: 'settings_inbox_list',
        children: [
          {
            id: 'inboxes',
            icon: 'inbox',
            label: 'INBOX',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/inboxes`),
            toStateName: 'settings_inbox_list',
          },
          {
            id: 'agents',
            icon: 'agent',
            label: 'AGENTS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/agents/list`),
            toStateName: 'agent_list',
          },
          {
            id: 'teams',
            icon: 'peopleFilled',
            label: 'TEAMS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/teams/list`),
            toStateName: 'settings_teams_list',
          },
          {
            id: 'automations',
            icon: 'automation',
            label: 'AUTOMATION',
            hasSubMenu: false,
            SEARCH_TXT: 'Search for agents, enter name/designation',
            toState: frontendURL(`accounts/${accountId}/settings/automation`),
            toStateName: 'automation_list',
          },
          {
            id: 'settings_custom_ticket_fields',
            icon: 'ticket',
            label: 'CUSTOM_TICKET_FIELDS',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/settings/custom-ticket-fields`
            ),
            toStateName: 'settings_custom_ticket_fields',
            isNew: true,
          },
          {
            id: 'ticket_assignment',
            icon: 'ticket',
            label: 'TICKET_ASSIGNMENT',
            hasSubMenu: false,
            isNew: false,
            toState: frontendURL(
              `accounts/${accountId}/settings/ticket-assignment`
            ),
            toStateName: 'ticket_assignment',
          },
          {
            id: 'settings_sla',
            icon: 'filterBtn',
            label: 'SLA',
            hasSubMenu: false,
            isNew: true,
            toState: frontendURL(
              `accounts/${accountId}/settings/service-level-agreements`
            ),
            toStateName: 'settings_sla',
          },
          {
            id: 'cannedResponses',
            icon: 'chatCanned',
            label: 'CANNED_RESPONSES',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/settings/canned-response/list`
            ),
            toStateName: 'canned_list',
          },
          {
            id: 'labels',
            icon: 'hash',
            label: 'LABELS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/labels/list`),
            toStateName: 'labels_list',
          },
          {
            id: 'settings_template',
            icon: 'template',
            label: 'TEMPLATE',
            hasSubMenu: false,
            toState: `${process.env.CAMPAIGN_URL}wa/templates?hd_account_id=${accountId}&fullscreen=true`,
            toStateName: 'settings_template',
            isExternal: true,
          },
          {
            id: 'settings_integrations',
            icon: 'integration',
            label: 'INTEGRATIONS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/integrations`),
            toStateName: 'settings_integrations',
          },
          {
            id: 'settings_product_list',
            icon: 'cart',
            label: 'PRODUCTS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/products`),
            toStateName: 'settings_product_list',
          },
          {
            id: 'bot_csat_config_settings',
            icon: 'bot',
            label: 'BOT_CSAT_CONFIG',
            hasSubMenu: false,
            toState: frontendURL(
              `accounts/${accountId}/settings/bot-csat-settings`
            ),
            toStateName: 'bot_csat_config_settings',
          },
          {
            id: 'general_settings_index',
            icon: 'account',
            label: 'ACCOUNT_SETTINGS',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/general`),
            toStateName: 'general_settings_index',
          },
          {
            id: 'settings_billing_plan',
            icon: 'card',
            label: 'BILLING',
            hasSubMenu: false,
            toState: frontendURL(`accounts/${accountId}/settings/billing_plan`),
            toStateName: 'settings_billing_plan',
          },
        ],
      },
    },
  },
});
