import { mapGetters } from 'vuex';

export const INBOX_TYPES = {
  WEB: 'Channel::WebWidget',
  FB: 'Channel::FacebookPage',
  TWITTER: 'Channel::TwitterProfile',
  TWILIO: 'Channel::TwilioSms',
  API: 'Channel::Api',
  EMAIL: 'Channel::Email',
  CHAT_API: 'Channel::ChatApi',
  GUPSHUP: 'Channel::Gupshup',
  GUPSHUP_ENTERPRISE: 'Channel::GupshupEnterprise',
  ZOKO: 'Channel::Zoko',
  ACL: 'Channel::Acl',
  MAYTAPI: 'Channel::Maytapi',
  FRESHCHAT: 'Channel::Freshchat',
  TWO_FACTOR: 'Channel::TwoFactor',
  WATI: 'Channel::Wati',
  VALUE_FIRST: 'Channel::ValueFirst',
  CLOUD_API: 'Channel::WhatsappCloudApi',
  KNOWLARITY: 'Channel::Knowlarity',
  THREE_SIXTY_DIALOG: 'Channel::ThreeSixtyDialog',
  ICS: 'Channel::Ics',
  INFOBIP: 'Channel::Infobip',
};

export const INBOX_NAMES = {
  'Channel::WebWidget': { key: 'website', name: 'Website' },
  'Channel::FacebookPage': { key: 'facebook', name: 'Facebook' },
  'Channel::TwitterProfile': { key: 'twitter', name: 'Twitter' },
  'Channel::TwilioSms': { key: 'twilio', name: 'Twilio SMS' },
  'Channel::Api': { key: 'api', name: 'Api' },
  'Channel::Email': { key: 'email', name: 'Email' },
  'Channel::ChatApi': { key: 'chat_api', name: 'Chat API' },
  'Channel::Gupshup': { key: 'gupshup', name: 'Gupshup' },
  'Channel::GupshupEnterprise': { key: 'gupshup', name: 'Gupshup Enterprise' },
  'Channel::Zoko': { key: 'zoko', name: 'Zoko' },
  'Channel::Acl': { key: 'acl', name: 'Acl' },
  'Channel::Maytapi': { key: 'maytapi', name: 'Maytapi' },
  'Channel::Freshchat': { key: 'freshchat', name: 'Freshchat' },
  'Channel::TwoFactor': { key: 'twofactor', name: 'TwoFactor' },
  'Channel::Wati': { key: 'wati', name: 'Wati' },
  'Channel::ValueFirst': { key: 'valuefirst', name: 'ValueFirst' },
  'Channel::Ics': { key: 'ics', name: 'Ics' },
  'Channel::Infobip': { key: 'infobip', name: 'Infobip' },
  'Channel::WhatsappCloudApi': {
    key: 'whatsapp_cloud_api',
    name: 'WhatsApp Cloud Api',
  },
  'Channel::Knowlarity': { key: 'knowlarity', name: 'Knowlarity' },
  'Channel::ThreeSixtyDialog': {
    key: 'three_sixty_dialog',
    name: '360 Dialog',
    component_name: 'Three Sixty Dialog',
  },
};

export const isAWhatsAppChannel = channelType => {
  return [
    INBOX_TYPES.CHAT_API,
    INBOX_TYPES.GUPSHUP,
    INBOX_TYPES.GUPSHUP_ENTERPRISE,
    INBOX_TYPES.WATI,
    INBOX_TYPES.ZOKO,
    INBOX_TYPES.MAYTAPI,
    INBOX_TYPES.ACL,
    INBOX_TYPES.VALUE_FIRST,
    INBOX_TYPES.FRESHCHAT,
    INBOX_TYPES.TWILIO,
    INBOX_TYPES.CLOUD_API,
    INBOX_TYPES.THREE_SIXTY_DIALOG,
    INBOX_TYPES.ICS,
    INBOX_TYPES.INFOBIP,
  ].includes(channelType);
};

export default {
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    channelType() {
      return this.inbox.channel_type;
    },
    isAKnowlarityInbox() {
      return this.channelType === INBOX_TYPES.KNOWLARITY;
    },
    isAPIInbox() {
      return this.channelType === INBOX_TYPES.API;
    },
    isATwitterInbox() {
      return this.channelType === INBOX_TYPES.TWITTER;
    },
    isAFacebookInbox() {
      return this.channelType === INBOX_TYPES.FB;
    },
    isAnInstagramInbox() {
      return this.isAFacebookInbox && this.inbox.instagram_add_on;
    },
    isAWebWidgetInbox() {
      return this.channelType === INBOX_TYPES.WEB;
    },
    isATwilioChannel() {
      return this.channelType === INBOX_TYPES.TWILIO;
    },
    isAnEmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL;
    },
    isATwilioSMSChannel() {
      const { phone_number: phoneNumber = '' } = this.inbox;
      return this.isATwilioChannel && !phoneNumber.startsWith('whatsapp');
    },
    isATwilioWhatsappChannel() {
      const { phone_number: phoneNumber = '' } = this.inbox;
      return this.isATwilioChannel && phoneNumber.startsWith('whatsapp');
    },
    isAChatAPIInbox() {
      return this.channelType === INBOX_TYPES.CHAT_API;
    },
    isMaytapiInbox() {
      return this.channelType === INBOX_TYPES.MAYTAPI;
    },
    isFreshchatInbox() {
      return this.channelType === INBOX_TYPES.FRESHCHAT;
    },
    isTwoFactorInbox() {
      return this.channelType === INBOX_TYPES.TWO_FACTOR;
    },
    isWatiInbox() {
      return this.channelType === INBOX_TYPES.WATI;
    },
    isValueFirstInbox() {
      return this.channelType === INBOX_TYPES.VALUE_FIRST;
    },
    isAclInbox() {
      return this.channelType === INBOX_TYPES.ACL;
    },
    isGupshupEnterpriseInbox() {
      return this.channelType === INBOX_TYPES.GUPSHUP_ENTERPRISE;
    },
    isWhatsappCloudApiChannel() {
      return this.channelType === INBOX_TYPES.CLOUD_API;
    },
    isThreeSixtyDialogInbox() {
      return this.channelType === INBOX_TYPES.THREE_SIXTY_DIALOG;
    },
    isWhatsappChannel() {
      return isAWhatsAppChannel(this.channelType);
    },
    isWhatsappInfobipChannel() {
      return this.channelType === INBOX_TYPES.INFOBIP;
    },
    identifier() {
      return this.currentChat.identifier;
    },
    isAFacebookComment() {
      return this.isAFacebookInbox && this.identifier === 'comment';
    },
    isInstagramComment() {
      return this.isAFacebookInbox && this.identifier === 'instagram_comment';
    },
    isFacebookDm() {
      return this.isAFacebookInbox && !this.identifier;
    },
    isInstagramDm() {
      return this.isAnInstagramInbox && !this.identifier;
    },
    hasReplyMessageSupport() {
      return (
        this.isAFacebookComment ||
        this.isInstagramComment ||
        this.isInstagramDm ||
        this.isFacebookDm ||
        this.isThreeSixtyDialogInbox ||
        this.isGupshupEnterpriseInbox ||
        this.isWhatsappCloudApiChannel ||
        this.isWhatsappInfobipChannel
      );
    },
    hasOptInSupport() {
      return [
        INBOX_TYPES.GUPSHUP,
        INBOX_TYPES.GUPSHUP_ENTERPRISE,
        INBOX_TYPES.WATI,
        INBOX_TYPES.ZOKO,
        INBOX_TYPES.ACL,
        INBOX_TYPES.VALUE_FIRST,
        INBOX_TYPES.CLOUD_API,
        INBOX_TYPES.THREE_SIXTY_DIALOG,
        INBOX_TYPES.ICS,
        INBOX_TYPES.INFOBIP,
      ].includes(this.channelType);
    },
    hasQueryParamInCallback() {
      return [
        INBOX_TYPES.FRESHCHAT,
        INBOX_TYPES.ZOKO,
        INBOX_TYPES.WATI,
      ].includes(this.channelType);
    },
  },
};
