<template>
  <div class="settings-container">
    <woot-base-button
      size="medium"
      front-icon="plus"
      icon-view-box="0 0 20 20"
      class="settings-button"
      @click="openAddPopup"
    >
      {{ $t('SLA.CREATE.TITLE') }}
    </woot-base-button>
    <div class="sla-container column settings-main-menu-new">
      <toolbar :search-term="searchTerm" @setSearch="searchTerm = $event" />
      <draggable-list
        :selected-sla="selectedSLA"
        :filters="filters"
        @view="selectedSLA = $event"
        @onCreate="openAddPopup"
      />
    </div>
    <settings-side-card>
      <div v-if="selectedSLA.id">
        <rhs-panel :sla="selectedSLA" />
      </div>
      <educational-onboarding v-else :show-case-data="SHOWCASE_SLA_DATA">
        <reference-box
          class="reference-box"
          :to="documentation.sla"
          content="Know more about Service Level Agreements"
          type="document"
        />
      </educational-onboarding>
    </settings-side-card>
    <create-modal
      :show="showCreateModal"
      :name="sla.name"
      :description="sla.description"
      @updateDetail="onUpdateDetail"
      @close="onCloseModal"
      @submit="onSubmitModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Toolbar from '../components/list/Toolbar';
import DraggableList from '../components/list/DraggableList';
import SettingsSideCard from '../../SettingSideCard';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import RhsPanel from '../components/list/RhsPanel';
import CreateModal from '../components/modals/CreateModal';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';

import { SHOWCASE_SLA_DATA } from '../utils/constants';
import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  components: {
    Toolbar,
    DraggableList,
    SettingsSideCard,
    RhsPanel,
    ReferenceBox,
    EducationalOnboarding,
    CreateModal,
  },
  data() {
    return {
      selectedSLA: { id: null },
      sla: { name: '', description: '' },
      searchTerm: '',
      SHOWCASE_SLA_DATA,
      showCreateModal: false,
      documentation,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'sla/getUIFlags' }),
    filters() {
      return { searchTerm: this.searchTerm };
    },
  },
  methods: {
    openAddPopup() {
      this.showCreateModal = true;
    },
    onUpdateDetail(data) {
      this.sla = { ...this.sla, ...data };
    },
    onCloseModal() {
      this.showCreateModal = false;
    },
    onSubmitModal() {
      this.onCloseModal();
      this.$router.push({
        name: 'settings_create_sla',
        params: { ...this.sla },
      });
      this.sla = { name: '', description: '' };
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  height: 100%;
  display: flex;
  flex-direction: row;

  .settings-side-card {
    padding: 0;
  }
}
</style>
