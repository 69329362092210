import Facebook from './channels/Facebook';
import Instagram from './channels/Instagram';
import Website from './channels/Website';
import Twitter from './channels/Twitter';
import Twilio from './channels/Twilio';
import ChatApi from './channels/ChatApi';
import Api from './channels/Api';
import Zoko from './channels/Zoko';
import Gupshup from './channels/Gupshup';
import GupshupEnterprise from './channels/GupshupEnterprise';
import Email from './channels/Email';
import Acl from './channels/Acl';
import Maytapi from './channels/Maytapi';
import Freshchat from './channels/Freshchat';
import TwoFactor from './channels/TwoFactor';
import Wati from './channels/Wati';
import ValueFirst from './channels/ValueFirst';
import WhatsappCloudApi from './channels/WhatsappCloudApi';
import Knowlarity from './channels/Knowlarity';
import ThreeSixtyDialog from './channels/ThreeSixtyDialog';
import Ics from './channels/Ics';
import Infobip from './channels/Infobip';
import NestedChannel from 'dashboard/routes/dashboard/settings/inbox/NestedChannelList';

const channelViewList = {
  facebook: Facebook,
  instagram: Instagram,
  website: Website,
  twitter: Twitter,
  twilio: Twilio,
  chat_API: ChatApi,
  api: Api,
  email: Email,
  zoko: Zoko,
  gupshup: Gupshup,
  acl: Acl,
  maytapi: Maytapi,
  freshchat: Freshchat,
  twofactor: TwoFactor,
  wati: Wati,
  ics: Ics,
  infobip: Infobip,
  value_first: ValueFirst,
  gupshup_enterprise: GupshupEnterprise,
  whatsapp_cloud_API: WhatsappCloudApi,
  three_sixty_dialog: ThreeSixtyDialog,
  knowlarity: Knowlarity,
  whatsapp_channels: NestedChannel,
  voice_channels: NestedChannel,
};

export default {
  create() {
    return {
      props: {
        channel_name: {
          type: String,
          required: true,
        },
      },
      name: 'new-channel-view',
      render(h) {
        return h(channelViewList[this.channel_name] || null, {
          props: { channelName: this.channel_name },
        });
      },
    };
  },
};
